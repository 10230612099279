import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  OktaAuthModule,
  OktaAuthService,
  OktaAuthGuard,
  OKTA_CONFIG,
} from "@okta/okta-angular";
import { ChartsModule } from "ng2-charts";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { SurveyModule } from "./survey/survey.module";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import {
  AuthenticationGuardService,
  AuthenticationService,
  getOktaConfig,
} from "./auth";
import {
  BootstrapModalModule,
  DialogService,
} from "./shared/modals/ng2-bootstrap-modal";
import { TokenInterceptor } from "./auth/token.interceptor";
import { CacheInterceptor } from "./shared/http-interceptors/cache-interceptor";
import { ErrorInterceptor } from "./shared/http-interceptors/error-interceptor";

import { UnsupportedBrowserErrorPageComponent } from "./shared/unsupported-browser-error-page/unsupported-browser-error-page.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

export function oktaConfigFactory() {
  return getOktaConfig();
}

@NgModule({
  declarations: [AppComponent, UnsupportedBrowserErrorPageComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    SharedModule,
    SurveyModule,
    DashboardModule,
    HttpClientModule,
    BootstrapModalModule,
    NgbModule,
    {
      ngModule: OktaAuthModule,
      providers: [OktaAuthGuard, OktaAuthService],
    },
    ChartsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthenticationGuardService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    DialogService,
    {
      provide: OKTA_CONFIG,
      useFactory: oktaConfigFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
