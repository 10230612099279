<p class="text-center question_survey">
  {{ question.questionText }}
</p>
<div class="middle_divider"></div>
<div class="cb row justify-content-center">
  <div
    *ngIf="
      !!question.options && question.options.length > 0;
      then show_options;
      else show_textarea
    "
  ></div>
  <ng-template #show_options>
    <div *ngFor="let opt of question.options" class="col-md-2">
      <div class="custom_radio_btn">
        <input
          [id]="'option_' + opt"
          class="cb__input"
          type="radio"
          name="cb"
          [value]="opt"
          (change)="optionChange(opt)"
          [checked]="opt === answer.questionAnswer"
        />
        <label [for]="'option_' + opt" class="cb__label">{{ opt }}</label>
      </div>
    </div>
  </ng-template>
  <ng-template #show_textarea>
    <textarea
      class="form-control"
      type="text"
      name="cb"
      [(ngModel)]="answer.questionAnswer"
    ></textarea>
  </ng-template>
</div>
<ng-container *ngIf="showConditionalQuestion()">
  <div class="cb row animated fadeIn justify-content-center">
    <p class="message col-md-12">
      {{ question.conditionalQuestion.questionText }}
    </p>
    <div
      *ngIf="
        !!question.conditionalQuestion.options &&
          question.conditionalQuestion.options.length > 0;
        then show_conditional_options;
        else show_conditional_textarea
      "
    ></div>
    <ng-template #show_conditional_options>
      <div
        *ngFor="let cond_opt of question.conditionalQuestion.options"
        class="col-md-2"
      >
        <div class="custom_radio_btn">
          <input
            [id]="'cond_option_' + cond_opt"
            class="cb__input"
            type="radio"
            name="opt_cb"
            [value]="cond_opt"
            (change)="conditionalOptionChange(cond_opt)"
            [checked]="cond_opt === answer.conditionalAnswer"
          />
          <label [for]="'cond_option_' + cond_opt" class="cb__label">{{
            cond_opt
          }}</label>
        </div>
      </div>
    </ng-template>
    <ng-template #show_conditional_textarea>
      <textarea
        class="form-control"
        type="text"
        name="opt_cb"
        [(ngModel)]="answer.conditionalAnswer"
      ></textarea>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="showAlternativeQuestion()">
  <p class="text-center question_survey"></p>
  <div class="middle_divider"></div>
  <div class="cb row animated fadeIn justify-content-center">
    <p class="message col-md-12">
      {{ question.alternativeQuestion.questionText }}
    </p>
    <div
      *ngIf="
        !!question.alternativeQuestion.options &&
          question.alternativeQuestion.options.length > 0;
        then show_alternative_options;
        else show_alternative_textarea
      "
    ></div>
    <ng-template #show_alternative_options>
      <div
        *ngFor="let alt_opt of question.alternativeQuestion.options"
        class="col-md-2"
      >
        <div class="custom_radio_btn">
          <input
            [id]="'alt_option_' + alt_opt"
            class="cb__input"
            type="radio"
            name="alt_cb"
            [value]="alt_opt"
            (change)="alternativeOptionChange(alt_opt)"
            [checked]="alt_opt === answer.alternativeAnswer"
          />
          <label [for]="'alt_option_' + alt_opt" class="cb__label">{{
            alt_opt
          }}</label>
        </div>
      </div>
    </ng-template>
    <ng-template #show_alternative_textarea>
      <textarea
        class="form-control"
        type="text"
        name="alt_cb"
        [(ngModel)]="answer.alternativeAnswer"
      ></textarea>
    </ng-template>
  </div>
</ng-container>
