import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AlertComponent } from "./alert/alert.component";
import { ConfirmComponent } from "./confirm/confirm.component";
import { DialogService } from "./ng2-bootstrap-modal";
import { GlobalConstants } from "../global-constants";

@Injectable()
export class AppAlertsService {
  public errTitle: string = "Server error";
  public errMsg: string = " due to a server error. Please try again.";

  constructor(private dialogService: DialogService) {}

  public alert(title: string, message: string): Observable<any> {
    return this.dialogService.addDialog(
      AlertComponent,
      {
        title: title,
        message: message,
      },
      {
        backdropColor: GlobalConstants.MODAL_BACKGROUND_COLOR,
      }
    );
  }

  public alertServerError(message: string): Observable<any> {
    return this.dialogService.addDialog(
      AlertComponent,
      {
        title: this.errTitle,
        message: message + this.errMsg,
      },
      {
        backdropColor: GlobalConstants.MODAL_BACKGROUND_COLOR,
      }
    );
  }

  public inform(title: string, message: string): Observable<any> {
    return this.dialogService.addDialog(
      AlertComponent,
      {
        title: title,
        message: message,
        isError: false,
      },
      {
        backdropColor: GlobalConstants.MODAL_BACKGROUND_COLOR,
      }
    );
  }

  public confirm(
    title: string,
    message: string,
    ok: string,
    cancel: string
  ): Observable<boolean> {
    return this.dialogService.addDialog(
      ConfirmComponent,
      {
        title: title,
        message: message,
        okButton: ok,
        cancelButton: cancel,
      },
      {
        backdropColor: GlobalConstants.MODAL_BACKGROUND_COLOR,
      }
    );
  }
}
