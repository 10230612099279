import { Survey } from "../../../shared/entities";

export class SurveyHeaders {
  public activeSurveys: Survey[];
  public completedSurveys: Survey[];
  public managerDirectReports: any[];
  public headOfCompany: string;

  constructor() {
    this.activeSurveys = [];
    this.completedSurveys = [];
    this.managerDirectReports = [];
  }
}
