<div awacSpinner *ngIf="!!isLoading"></div>
<div class="row">
  <div class="col-12 col-lg-12">
    <div class="separation">
      <div class="row">
        <div class="col-12 col-lg-12">
          <p class="info">Active Delegations</p>
          <button
            (click)="openDelegatePopup()"
            class="btn btn btn-primary btnRed float-right"
          >
            <span class="fa fa-plus"></span> Add Delegation
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-8 col-lg-8">
          <div class="form-group mar-0">
            <input
              type="search"
              class="form-control"
              [attr.placeholder]="'Search Manager name or Employee name'"
              [(ngModel)]="searchText"
            />
          </div>
        </div>
        <div
          class="col-sm-6 separation"
          *ngFor="let delegation of existingDelegation | filter: searchText"
        >
          <div class="card">
            <div class="card-body delegate-details">
              <div class="row">
                <div class="col-lg-8">
                  <div class="form-group row">
                    <label class="col-sm-5">Original Manager </label>
                    <p
                      class="col-sm-7"
                      [innerHTML]="
                        delegation.originalManager.name | highlight: searchText
                      "
                    ></p>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-5">Delegated Manager </label>
                    <p
                      class="col-sm-7"
                      [innerHTML]="
                        delegation.delegatedManager.name | highlight: searchText
                      "
                    ></p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <button
                    class="btn btn btn-warning separation"
                    (click)="stopDelegationClick(delegation)"
                  >
                    <span class="fa fa-stop"></span> Stop Delegation
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-10">
                  <div class="form-group row">
                    <label class="col-sm-4">Period</label>
                    <p class="col-sm-8">
                      {{ delegation.startDate | date: "dd MMM  yyyy" }} -
                      {{
                        !delegation.endDate
                          ? "Unspecified"
                          : (delegation.endDate | date: "dd MMM yyyy")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="row form-group">
                <label class="col-sm-4 custom-width-label"
                  >Employee(s) assigned
                </label>
                <div
                  class="col-sm-6 employee-list-container length-{{
                    delegation.directReports.length
                  }}"
                >
                  <div
                    class="employee-name"
                    [innerHTML]="
                      delegation.directReports[0].name | highlight: searchText
                    "
                    *ngIf="delegation.directReports[0]"
                  ></div>
                  <div
                    class="employee-name"
                    [innerHTML]="
                      delegation.directReports[1].name | highlight: searchText
                    "
                    *ngIf="delegation.directReports[1]"
                  ></div>
                  <p>
                    {{
                      delegation.directReports.length > 2
                        ? "+" + (delegation.directReports.length - 2)
                        : ""
                    }}
                  </p>
                </div>
                <p
                  class="col-sm-2 more-link"
                  (click)="openMoreInfo(delegation)"
                >
                  More
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 separation"
          *ngIf="!existingDelegation.length && !isLoading"
        >
          <p class="dashboard-list-msg">
            <span class="fa fa-info-circle"></span> No active delegation.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
