import { Component, OnInit } from "@angular/core";
import { SessionStorageHandling } from "../storage/session-storage-handling";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "srvy-unsupported-browser-error-page",
  templateUrl: "./unsupported-browser-error-page.component.html",
  styleUrls: ["./unsupported-browser-error-page.component.css"],
})
export class UnsupportedBrowserErrorPageComponent implements OnInit {
  public origin: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.origin = document.location.origin;
  }

  ngOnInit() {}

  continue() {
    SessionStorageHandling.allowInternetExplorerUsage();
  }

  copyToClipboard() {
    const textarea = document.createElement("textarea");
    textarea.style.position = "fixed";
    textarea.style.left = "0";
    textarea.style.top = "0";
    textarea.style.opacity = "0";
    textarea.value = this.origin;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
}
