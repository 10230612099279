import { Component, OnInit } from "@angular/core";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { DialogComponent } from "src/app/shared/modals/ng2-bootstrap-modal";
import { Employee, Reportee, AddDelegation } from "../../common/models";
import { DashboardService } from "../../common/services/dashboard.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "srvy-delegate-modal",
  templateUrl: "./delegate-modal.component.html",
  styleUrls: ["./delegate-modal.component.css"],
})
export class DelegateModalComponent
  extends DialogComponent<null, boolean>
  implements OnInit
{
  public datePickerOptions: IAngularMyDpOptions;
  public startDate: IMyDateModel;
  public endDate: IMyDateModel;
  public comments: string;
  public originalManager: string;
  public delegatedManager: string;
  public managerList: Employee[] = [];
  public delegatedList: Employee[];
  public isLoading: boolean;
  public serverError: boolean;
  public duration: string;
  public reportees: Reportee;
  public selectAll: boolean;
  public selectedReportees: string[] = [];
  public durationList: { value: string; name: string }[] = [
    { value: "unspecified", name: "Unspecified" },
    { value: "30", name: "30 days" },
    { value: "60", name: "60 days" },
    { value: "90", name: "90 days" },
    { value: "6m", name: "6 Months" },
    { value: "1y", name: "1 Year" },
    { value: "0", name: "Select specific date " },
  ];
  constructor(
    private dashboardService: DashboardService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.getManagerList();
    const today = new Date();
    this.datePickerOptions = {
      dateFormat: "mmm-dd-yyyy",
      selectorHeight: "36px",
      disableUntil: {
        day: today.getDate() - 1,
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      },
    };
  }
  /**
   * @description GET employee list who has reportees under them
   */
  getManagerList() {
    this.isLoading = true;
    this.dashboardService.getManagerList().subscribe(
      (list: Employee[]) => {
        this.managerList = list;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.serverError = true;
      }
    );
  }
  getAddDelegationData() {
    const data: AddDelegation = {
      id: null,
      originalManager: this.originalManager,
      originalManagerName: null,
      delegatedManager: this.delegatedManager,
      delegatedManagerName: null,
      startDate: `${this.startDate.singleDate.date.year}-${(
        "0" + this.startDate.singleDate.date.month
      ).slice(-2)}-${("0" + this.startDate.singleDate.date.day).slice(
        -2
      )}T00:00:00`,
      endDate: this.getEndDate(
        this.getDateFromPickerData(this.startDate.singleDate.date)
      ),
      employees: this.selectedReportees,
      comments: this.comments,
    };
    return data;
  }
  /**
   * @description Call add delegation API
   */
  update() {
    this.isLoading = true;
    this.dashboardService.addDelegation(this.getAddDelegationData()).subscribe(
      (response: boolean) => {
        this.isLoading = false;
        if (response) {
          this.result = true;
          this.close();
        } else {
          this.toastrService.error("Something went wrong", "Error!!");
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastrService.error("Something went wrong", "Error!!");
      }
    );
  }
  /**
   * @description Create end date from start date using the period value
   * @param startDate <Date>
   */
   getEndDate(startDate: Date): string {
    let endDate: Date;
    switch (this.duration) {
      case "30":
      case "60":
      case "90":
        endDate = new Date(
          startDate.setDate(startDate.getDate() + parseInt(this.duration))
        );
        break;
      case "6m":
        endDate = new Date(startDate.setMonth(startDate.getMonth() + 6));
        break;
      case "1y":
        endDate = new Date(startDate.setMonth(startDate.getMonth() + 12));
        break;
      case "0":
        endDate = this.getDateFromPickerData(this.endDate.singleDate.date);
        break;
      case "unspecified": {
        return null;
      }
    }
    return `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(
      -2
    )}-${("0" + endDate.getDate()).slice(-2)}T00:00:00`;
  }
  /**
   * @description Create date var from date picker data
   * @param date <Date picker data>
   */
  getDateFromPickerData(date: {
    year: number;
    month: number;
    day: number;
  }): Date {
    return new Date(date.year, date.month - 1, date.day);
  }
  /**
   * @description get name from network ID
   * @param networkId <string>
   */
  getEmployeeName(networkId: string): string {
    const match = this.managerList.find((emp) => {
      return emp.networkId === networkId;
    });
    return match ? match.name : "";
  }
  /**
   * @description Remove Original manager from manager list
   * @returns managers for delegation
   */
  managerChanged(): void {
    if (this.originalManager === this.delegatedManager) {
      this.delegatedManager = "";
    }
    this.delegatedList = this.managerList.filter((item) => {
      return !this.originalManager || item.networkId !== this.originalManager;
    });
  }
  /**
   * @description call service method to fetch employees who are reporting to the original manager
   */
  getReportees(): void {
    if (this.originalManager) {
      this.dashboardService
        .getReporteesList(this.originalManager)
        .subscribe((data) => {
          this.reportees = data;
        });
    } else {
      this.reportees = null;
    }
  }
  /**
   * @description method to handle select all check box
   */
  selectAllReportees() {
    this.selectedReportees = [];
    this.reportees.directReports = this.reportees.directReports.map(
      (reportee) => {
        reportee["check"] = this.selectAll;
        if (this.selectAll) {
          this.selectedReportees.push(reportee.networkId);
        }
        return reportee;
      }
    );
  }
  /**
   * @description method to handle individual selection employee
   * @param reportee <employee> direct report
   */
  reporteeSelectionChanged(reportee: any) {
    if (reportee["check"]) {
      this.selectedReportees.push(reportee.networkId);
      if (
        this.selectedReportees.length === this.reportees.directReports.length
      ) {
        this.selectAll = true;
      }
    } else {
      this.selectedReportees = this.selectedReportees.filter((item) => {
        return reportee.networkId !== item;
      });
      this.selectAll = false;
    }
  }
  /**
   * @description tool tip text for employees by delegation details
   * @param period <start and end date>
   */
  getDelegationPeriodText(period: { start: string; end: string }): string {
    return `Delegated from ${new Date(
      period.start
    ).toDateString()} - ${new Date(period.end).toDateString()}`;
  }
}
