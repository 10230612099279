import { OnDestroy, EventEmitter, Output, Component } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { DialogWrapperComponent } from "./dialog-wrapper.component";

/**
 * Abstract dialog
 * @template T - dialog data;
 * @template T1 - dialog result
 */
@Component({ template: "" })
export class DialogComponent<T, T1> implements OnDestroy {
  @Output() remove = new EventEmitter<DialogComponent<T, T1>>();

  /**
   * Observer to return result from dialog
   */
  private observer: Observer<T1>;

  /**
   * Dialog result
   * @param <T1>
   */
  protected result: T1;

  /**
   * Dialog wrapper (modal placeholder)
   */
  wrapper: DialogWrapperComponent;

  /**
   * Constructor
   */
  constructor() {}

  /**
   *
   * @param <T> data
   * @return <Observable<T1>>
   */
  fillData(data: T): Observable<T1> {
    data = data || <T>{};
    const keys = Object.keys(data);
    for (let i = 0, length = keys.length; i < length; i++) {
      const key = keys[i];
      this[key] = data[key];
    }
    return Observable.create((observer) => {
      this.observer = observer;
      return () => {
        this.close();
      };
    });
  }

  /**
   * Closes dialog
   */
  close(): void {
    this.remove.emit(this);
  }

  /**
   * OnDestroy handler
   * Sends dialog result to observer
   */
  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.next(this.result);
    }
  }
}
