export class Question {
  public id: string;
  public surveyId: string;
  public order: number;
  public questionText: string;
  public options: string[];
  public required: boolean;
  public singleSelect: boolean;
  public displayForOptions: string[];
  public conditionalEndsFlow: boolean;
  public conditionalQuestion: Question;
  public alternativeQuestion: Question;
}
