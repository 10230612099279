<h3 class="text-center question_survey">Summary</h3>
<div class="middle_divider"></div>

<div *ngFor="let an of survey.answers; let i = index" class="questionSummary">
  <div class="title">
    <span class="questionNumber">{{ i + 1 }}</span>
    <h4>{{ questions[i].questionText }}</h4>
  </div>
  <div class="cb row justify-content-center">
    <div
      *ngIf="
        !!questions[i].options && questions[i].options.length > 0;
        then show_options;
        else show_textarea
      "
    ></div>
    <ng-template #show_options>
      <div *ngFor="let opt of questions[i].options" class="col-md-2">
        <div class="custom_radio_btn">
          <input
            class="cb__input"
            type="radio"
            [checked]="opt === an.questionAnswer"
          />
          <label class="cb__label">{{ opt }}</label>
        </div>
      </div>
    </ng-template>
    <ng-template #show_textarea>
      <textarea
        class="form-control"
        type="text"
        name="cb"
        [(ngModel)]="an.questionAnswer"
        disabled="disabled"
      ></textarea>
    </ng-template>
    <div
      *ngIf="!!an.conditionalAnswer"
      class="cb row animated fadeIn col-md-12 justify-content-center"
    >
      <p class="message col-md-12">
        {{ questions[i].conditionalQuestion.questionText }}
      </p>
      <div
        *ngIf="
          !!questions[i].conditionalQuestion.options &&
            questions[i].conditionalQuestion.options.length > 0;
          then show_conditional_options;
          else show_conditional_textarea
        "
      ></div>
      <ng-template #show_conditional_options>
        <div
          *ngFor="let cond_opt of questions[i].conditionalQuestion.options"
          class="col-md-2"
        >
          <div class="custom_radio_btn">
            <input
              class="cb__input"
              type="radio"
              [checked]="cond_opt === an.conditionalAnswer"
            />
            <label class="cb__label">{{ cond_opt }}</label>
          </div>
        </div>
      </ng-template>
      <ng-template #show_conditional_textarea>
        <textarea
          class="form-control"
          type="text"
          name="cb"
          [(ngModel)]="an.conditionalAnswer"
          disabled="disabled"
        ></textarea>
      </ng-template>
    </div>
    <div
      *ngIf="!!an.alternativeAnswer"
      class="cb row animated fadeIn col-md-12 justify-content-center"
    >
      <p class="message col-md-12">
        {{ questions[i].alternativeQuestion.questionText }}
      </p>
      <div
        *ngIf="
          !!questions[i].alternativeQuestion.options &&
            questions[i].alternativeQuestion.options.length > 0;
          then show_alternative_options;
          else show_alternative_textarea
        "
      ></div>
      <ng-template #show_alternative_options>
        <div
          *ngFor="let alt_opt of questions[i].alternativeQuestion.options"
          class="col-md-2"
        >
          <div class="custom_radio_btn">
            <input
              class="cb__input"
              type="radio"
              [checked]="alt_opt === an.alternativeAnswer"
            />
            <label class="cb__label">{{ cond_opt }}</label>
          </div>
        </div>
      </ng-template>
      <ng-template #show_alternative_textarea>
        <textarea
          class="form-control"
          type="text"
          name="cb"
          [(ngModel)]="an.alternativeAnswer"
          disabled="disabled"
        ></textarea>
      </ng-template>
    </div>
  </div>
</div>
