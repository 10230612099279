import { UserRole } from "../../../auth/models";

export class Hierarchy {
  employeeName: string;
  employeeRole: UserRole;
  office: string;
  department: string;
  directReports: Hierarchy[];
  email: string;
  networkId: string;
}
