<div class="unsupported_browser_error_page">
  <div class="col-lg-6 col-lg-offset-3">
    <h2>Warning</h2>
    <h1>Some features of AW Connect may not work with Internet Explorer.</h1>
    <p>For a better experience please use Google Chrome.</p>
    <div class="btn_copy">
      <i class="fa fa-copy"></i>
      <input
        type="button"
        class="btn btn-block btn-finish btn-primary btn_AW_special color_tooltip"
        href=""
        (click)="copyToClipboard()"
        value="{{ origin }}"
        placement="top"
        ngbTooltip="Click to copy URL"
      />
    </div>
    <a class="ie_link" href="/" (click)="continue()"
      >Continue using Internet Explorer</a
    >
  </div>
</div>
