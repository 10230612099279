<div awacSpinner *ngIf="!!isLoading"></div>
<div *ngIf="!!serverError; then invalid_parameters; else show_surveys"></div>
<ng-template #invalid_parameters>
  <p class="no_information_message pointer" (click)="resetPage()">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    There was an error while trying to retrieve document list.
    <u>Please try again</u>
  </p>
</ng-template>
<ng-template #show_surveys>
  <form action="">
    <div class="row">
      <div class="col-5 col-lg-7">
        <div class="form-group">
          <input
            type="search"
            title="Enter at least 3 letters for search"
            class="form-control"
            name="searchText"
            [(ngModel)]="searchText"
            placeholder="Search Surveys by Employee Name"
          />
        </div>
      </div>
      <div class="col-2 col-lg-2">
        <button
          type="submit"
          class="btn btn-primary btn-lg btnRed"
          [disabled]="searchText.trim().length < 3"
          (click)="showReopenSurvey = true; getCompletedSurveys()"
        >
          <span class="fa fa-search"></span>
          Search
        </button>
      </div>

      <div class="col-4 col-lg-3" *ngIf="showReopenSurvey">
        <div class="show_pagination">
          Show
          <a
            class="btn btn-light btn-sm"
            [class.active]="maxItemsPerPage === 10"
            (click)="setPaging(10)"
            >10</a
          >
          <a
            class="btn btn-light btn-sm"
            [class.active]="maxItemsPerPage === 20"
            (click)="setPaging(20)"
            >20</a
          >
          <a
            class="btn btn-light btn-sm"
            [class.active]="maxItemsPerPage === 50"
            (click)="setPaging(50)"
            >50</a
          >
          <a
            class="btn btn-light btn-sm"
            [class.active]="maxItemsPerPage === 100"
            (click)="setPaging(100)"
            >100</a
          >
          <a
            class="btn btn-light btn-sm"
            [class.active]="maxItemsPerPage > 100"
            (click)="setPaging(200)"
            >All</a
          >
        </div>
      </div>
    </div>
  </form>
  <div class="" *ngIf="showReopenSurvey">
    <div class="card">
      <div class="tableCont">
        <table class="table table-striped mar-b-0">
          <thead>
            <tr>
              <th>
                <a (click)="sortType('targetName')" class="sort_caret">
                  Employee Name
                  <span
                    *ngIf="sortProp !== 'targetName'"
                    class="placeholder_caret"
                  ></span>
                  <span
                    *ngIf="sortProp === 'targetName' && !sortReverse"
                    class="fa fa-caret-down"
                  ></span>
                  <span
                    *ngIf="sortProp === 'targetName' && sortReverse"
                    class="fa fa-caret-up"
                  ></span>
                </a>
              </th>
              <th>
                <a (click)="sortType('surveyTypeName')" class="sort_caret">
                  Survey type
                  <span
                    *ngIf="sortProp !== 'surveyTypeName'"
                    class="placeholder_caret"
                  ></span>
                  <span
                    *ngIf="sortProp === 'surveyTypeName' && !sortReverse"
                    class="fa fa-caret-down"
                  ></span>
                  <span
                    *ngIf="sortProp === 'surveyTypeName' && sortReverse"
                    class="fa fa-caret-up"
                  ></span>
                </a>
              </th>
              <th>
                <a (click)="sortType('quarterName')" class="sort_caret">
                  Quarter
                  <span
                    *ngIf="sortProp !== 'quarterName'"
                    class="placeholder_caret"
                  ></span>
                  <span
                    *ngIf="sortProp === 'quarterName' && !sortReverse"
                    class="fa fa-caret-down"
                  ></span>
                  <span
                    *ngIf="sortProp === 'quarterName' && sortReverse"
                    class="fa fa-caret-up"
                  ></span>
                </a>
              </th>
              <th>
                <a (click)="sortType('completedDate')" class="sort_caret">
                  Completed Date
                  <span
                    *ngIf="sortProp !== 'completedDate'"
                    class="placeholder_caret"
                  ></span>
                  <span
                    *ngIf="sortProp === 'completedDate' && !sortReverse"
                    class="fa fa-caret-down"
                  ></span>
                  <span
                    *ngIf="sortProp === 'completedDate' && sortReverse"
                    class="fa fa-caret-up"
                  ></span>
                </a>
              </th>
              <th>
                <a (click)="sortType('employeeOffice')" class="sort_caret">
                  Office
                  <span
                    *ngIf="sortProp !== 'employeeOffice'"
                    class="placeholder_caret"
                  ></span>
                  <span
                    *ngIf="sortProp === 'employeeOffice' && !sortReverse"
                    class="fa fa-caret-down"
                  ></span>
                  <span
                    *ngIf="sortProp === 'employeeOffice' && sortReverse"
                    class="fa fa-caret-up"
                  ></span>
                </a>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of completedSurveys
                  | orderBy: [sortProp, 'targetName']:sortReverse:true
                  | paginate
                    : {
                        itemsPerPage: maxItemsPerPage,
                        currentPage: pageNumber
                      };
                let i = index
              "
            >
              <td>{{ item.targetName }}</td>
              <td>{{ item.surveyTypeName }}</td>
              <td>{{ item.quarterName }}</td>
              <td>{{ item.completedDate | date: "dd MMM  yyyy" }}</td>
              <td>{{ item.targetOffice }}</td>
              <td>
                <a class="float-right" (click)="reopen(item)">Reopen</a>
              </td>
            </tr>
            <tr *ngIf="!completedSurveys.length">
              <td colspan="6">
                {{ isLoading ? "Loading..." : "No items found..." }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination-controls
      (pageChange)="pageNumber = $event"
    ></pagination-controls>
  </div>
</ng-template>
