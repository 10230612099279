import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../auth";
import { User } from "../../../auth/models";

@Component({
  selector: "srvy-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public loggedUser: string;
  public serverError: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.getUserData().subscribe((user: User) => {
      if (!user || !user.displayName) {
        this.serverError = true;
      } else {
        this.loggedUser = user.displayName;
        this.serverError = false;
      }
    });
  }

  navigateToHome(): void {
    this.router.navigate(["/dashboard"]);
  }

  logout(): void {
    this.authService.logout();
  }
}
