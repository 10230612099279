import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from "@angular/core";
import {
  SelectedOptions,
  Metrics,
  FilterOptions,
  EmployeeMetric,
} from "../common/models";
import { Router } from "@angular/router";

@Component({
  selector: "srvy-metrics",
  templateUrl: "./metrics.component.html",
  styleUrls: ["./metrics.component.css"],
})
export class MetricsComponent implements OnInit, OnChanges {
  @Input() metrics: Metrics = new Metrics();
  @Input() filterOptions: FilterOptions = new FilterOptions();
  @Input() selected: SelectedOptions = new SelectedOptions();
  // tslint:disable-next-line:no-output-rename
  @Output("selectedOptionsEvent")
  selectedOptions: EventEmitter<SelectedOptions> = new EventEmitter<SelectedOptions>();
  public defaultSelected: SelectedOptions = new SelectedOptions();
  public filteredCompletedSurvey: EmployeeMetric[] = [];
  public filteredPendingSurvey: EmployeeMetric[] = [];
  public completedPercentage: number = 0;
  public pendingPercentage: number = 0;

  public chartData = [];
  public chartLabels = ["Completed", "Pending", "No Data"];
  public chartColors = [
    { backgroundColor: ["#53C43E", "#E0492E", "gainsboro"] },
  ];

  constructor(private router: Router) {}

  ngOnInit() {}

  ngOnChanges() {
    this.defaultSelected = {
      template: this.selected.template,
      period: this.selected.period,
      year: this.selected.year,
      manager: this.selected.manager,
      employee: null,
      office: this.selected.office,
      department: this.selected.department,
    };
    const office = this.defaultSelected.office;
    const department = this.defaultSelected.department;
    this.filteredCompletedSurvey = this.filterEmployees(
      this.metrics.completedSurveyEmployees,
      office,
      department
    );
    this.filteredPendingSurvey = this.filterEmployees(
      this.metrics.pendingSurveyEmployees,
      office,
      department
    );
    this.calculatePercentage();
  }

  calculatePercentage() {
    if (
      !!this.metrics &&
      !!this.filteredCompletedSurvey &&
      !!this.filteredPendingSurvey
    ) {
      this.completedPercentage = this.getCompletedPercentage(
        this.filteredCompletedSurvey.length,
        this.filteredPendingSurvey.length
      );
      this.pendingPercentage = this.getPendingPercentage(
        this.completedPercentage,
        this.filteredPendingSurvey.length
      );
    }

    this.chartData = [this.completedPercentage, this.pendingPercentage];
    if (this.completedPercentage === 0 && this.pendingPercentage === 0) {
      this.chartData.push(100);
    }
  }

  getCompletedPercentage(
    completedSurveys: number,
    pendingSurveys: number
  ): number {
    return completedSurveys === 0
      ? 0
      : Math.ceil(
          Number(
            (
              (completedSurveys * 100) /
              (completedSurveys + pendingSurveys)
            ).toFixed(3)
          )
        );
  }

  getPendingPercentage(
    completedPercentage: number,
    pendingSurveys: number
  ): number {
    return pendingSurveys === 0
      ? 0
      : 100 -
          (completedPercentage === 100 && pendingSurveys > 0
            ? 99
            : completedPercentage);
  }

  viewMetrics() {
    if (
      this.selected.template !== this.defaultSelected.template ||
      this.selected.period !== this.defaultSelected.period ||
      this.selected.year !== this.defaultSelected.year ||
      this.selected.manager !== this.defaultSelected.manager
    ) {
      this.selectedOptions.emit(this.getSelectedOptions());
    } else {
      const office = this.selected.office;
      const department = this.selected.department;
      this.filteredCompletedSurvey = this.filterEmployees(
        this.metrics.completedSurveyEmployees,
        office,
        department
      );
      this.filteredPendingSurvey = this.filterEmployees(
        this.metrics.pendingSurveyEmployees,
        office,
        department
      );
      this.calculatePercentage();
    }
  }

  getSelectedOptions(): SelectedOptions {
    return {
      template: this.selected.template,
      period: this.selected.period,
      year: this.selected.year,
      manager: this.selected.manager,
      employee: null,
      office: null,
      department: null,
    };
  }

  filterEmployees(
    employees: EmployeeMetric[],
    filter: string,
    secondFilter: string
  ) {
    if (!employees) {
      return [];
    }
    return employees.filter((employee) => {
      return (
        (!filter ||
          (!!employee.office &&
            employee.office.toLowerCase() === filter.toLowerCase())) &&
        (!secondFilter ||
          (!!employee.department &&
            employee.department.toLowerCase() === secondFilter.toLowerCase()))
      );
    });
  }

  navigateToSearch() {
    this.router.navigate(["/search"]);
  }
}
