import { Injectable, Injector, Inject } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import * as OktaAuth from "@okta/okta-auth-js";

import { AuthenticationService } from "./authentication.service";
import { environment } from "../../environments/environment";
import { OktaAuthService, OKTA_CONFIG } from "@okta/okta-angular";
import { OktaConfig } from "@okta/okta-angular/src/okta/models/okta.config";
import { GlobalConstants } from "../shared/global-constants";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public authClient = new OktaAuth({
    issuer: this.oktaConfig.issuer,
    url: this.oktaConfig.redirectUri,
  });

  constructor(
    private inj: Injector,
    private oktaAuth: OktaAuthService,
    @Inject(OKTA_CONFIG) private oktaConfig: OktaConfig
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.inj.get(AuthenticationService);
    if (request.url.indexOf("assets") !== -1) {
      return next.handle(request);
    } else {
      return from(this.oktaAuth.isAuthenticated()).pipe(
        mergeMap((isAuthenticated) => {
          if (
            !isAuthenticated &&
            !request.url.startsWith(environment.authenticateApiUrlOkta)
          ) {
            const sdk = {
              responseType: ["id_token", "token"],
              clientId: this.oktaConfig.clientId,
              issuer: this.oktaConfig.issuer,
              redirectUri: this.oktaConfig.redirectUri,
              scopes: this.oktaConfig.scopes,
              responseMode: "fragment",
              pkce: this.oktaConfig.pkce,
            };
            const options = {
              issuer: this.oktaConfig.issuer,
            };
            return from(this.authClient.token.getWithPopup(sdk, options)).pipe(
              mergeMap((data) => {
                if (!data || !data[0] || !data[0]["idToken"]) {
                  this.oktaAuth.loginRedirect(GlobalConstants.DEFAULT_PAGE);
                } else {
                  auth.setToken(data[0]["idToken"]);
                  request = this.updateToken(request, auth);
                }

                return next.handle(request);
              })
            );
          } else if (
            !request.url.startsWith(environment.authenticateApiUrlOkta)
          ) {
            request = this.updateToken(request, auth);
            return next.handle(request);
          } else {
            return next.handle(request);
          }
        })
      );
    }
  }

  updateToken(request: HttpRequest<any>, auth: AuthenticationService) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${auth.getToken()}`,
      },
    });
  }
}
