<section class="container">
  <div class="row back_button">
    <button
      *ngIf="!!loggedUser && loggedUser.networkId !== networkId"
      (click)="goToDashboard(loggedUser)"
      class="btn btn-link btn-sm"
    >
      <i class="fa fa-chevron-left"></i> Back to my dashboard
    </button>
  </div>
  <div class="row">
    <div class="col-auto">
      <h2>
        {{
          !!loggedUser && loggedUser.networkId === networkId
            ? "My Dashboard"
            : dashboardTitle()
        }}
      </h2>
    </div>
    <div class="col-auto form-inline d-flex justify-content-end">
      <div *ngIf="!!managersHierarchy" class="form-group view_as">
        <label>View As:</label>
        <select
          [(ngModel)]="selectedUser"
          (change)="goToDashboard(selectedUser)"
          class="form-control"
        >
          <option selected value="undefined">Select Employee</option>
          <option
            *ngFor="let directReport of managersHierarchy"
            [ngValue]="directReport"
          >
            {{ directReport.name }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="col-12 col-md form-inline justify-content-end align-items-start"
    >
      <button
        type="button"
        class="btn btn-primary btn-lg btnRed btnSearchSurveys"
        (click)="navigateToSearch()"
      >
        <span class="fa fa-search"></span> Search surveys
      </button>
    </div>
  </div>
  <div class="row">
    <!-- Active list -->
    <div class="col-lg-6 surveys-column">
      <div class="dashboard-head">
        <div>
          <span>{{
            !!surveyHeaders.activeSurveys
              ? surveyHeaders.activeSurveys.length
              : 0
          }}</span>
        </div>
        <div>
          <h3><span class="fa fa-calendar-plus-o"></span> Active Surveys</h3>
        </div>
      </div>
      <div class="dashboard-list">
        <div
          class="tableCont tableScroll"
          *ngIf="
            (!!managerActiveSurveys && managerActiveSurveys.length > 0) ||
            (!!personalActiveSurveys && personalActiveSurveys.length > 0)
          "
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col" class="text-left">Remaining days</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  !!personalActiveSurveys && personalActiveSurveys.length > 0
                "
              >
                <tr *ngFor="let active of personalActiveSurveys">
                  <td>{{ active.title }}</td>
                  <td>
                    {{ activeRemainingDays(active) }} day(s) left
                    <button
                      *ngIf="!!loggedUser && loggedUser.networkId === networkId"
                      (click)="navigateToSurvey(active.id)"
                      class="btn btn-primary align-self-center"
                    >
                      Open survey <span class="fa fa-arrow-right"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
              <tr
                *ngIf="
                  !!managerActiveSurveys &&
                  managerActiveSurveys.length > 0 &&
                  !!personalActiveSurveys &&
                  personalActiveSurveys.length > 0
                "
                class="white_divider"
              >
                <td colspan="2"></td>
              </tr>
              <ng-container
                *ngIf="
                  !!managerActiveSurveys && managerActiveSurveys.length > 0
                "
              >
                <tr *ngFor="let active of managerActiveSurveys">
                  <td>{{ active.title }}</td>
                  <td>
                    {{ activeRemainingDays(active) }} day(s) left
                    <button
                      *ngIf="!!loggedUser && loggedUser.networkId === networkId"
                      (click)="navigateToSurvey(active.id)"
                      class="btn btn-primary align-self-center"
                    >
                      Open survey <span class="fa fa-arrow-right"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <p
          class="dashboard-list-msg"
          *ngIf="
            !!managerActiveSurveys &&
            managerActiveSurveys.length === 0 &&
            !!personalActiveSurveys &&
            personalActiveSurveys.length === 0
          "
        >
          <span class="fa fa-info-circle"></span> No active surveys.
        </p>
      </div>
    </div>
    <!-- Recently Completed list -->
    <div class="col-lg-6 surveys-column">
      <div class="dashboard-head">
        <div>
          <span>{{
            !!surveyHeaders.completedSurveys
              ? surveyHeaders.completedSurveys.length
              : 0
          }}</span>
        </div>
        <div>
          <h3>
            <span class="fa fa-calendar-check-o"></span> Recently Completed
            Surveys
          </h3>
        </div>
      </div>
      <div class="dashboard-list list-completed">
        <div
          class="tableCont tableScroll"
          *ngIf="
            (!!managerCompletedSurveys && managerCompletedSurveys.length > 0) ||
            (!!personalCompletedSurveys && personalCompletedSurveys.length > 0)
          "
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col" class="text-center">Completed date</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  !!personalCompletedSurveys &&
                  personalCompletedSurveys.length > 0
                "
              >
                <tr *ngFor="let completed of personalCompletedSurveys">
                  <td>{{ completed.title }}</td>
                  <td>
                    {{ completed.completedDate | date: "M/d/yyyy" }}
                    <button
                      *ngIf="
                        (!!loggedUser && loggedUser.networkId === networkId) ||
                        completed.questionSetId ===
                          'a600fa90-57cd-4b87-b2df-4765e9cb890d'
                      "
                      (click)="navigateToSurvey(completed.id)"
                      class="btn btn-primary align-self-center"
                    >
                      View <span class="fa fa-eye"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
              <tr
                *ngIf="
                  !!managerCompletedSurveys &&
                  managerCompletedSurveys.length > 0 &&
                  !!personalCompletedSurveys &&
                  personalCompletedSurveys.length > 0
                "
                class="white_divider"
              >
                <td colspan="2"></td>
              </tr>
              <ng-container
                *ngIf="
                  !!managerCompletedSurveys &&
                  managerCompletedSurveys.length > 0
                "
              >
                <tr *ngFor="let completed of managerCompletedSurveys">
                  <td>{{ completed.title }}</td>
                  <td>
                    {{ completed.completedDate | date: "M/d/yyyy" }}
                    <button
                      *ngIf="
                        (!!loggedUser && loggedUser.networkId === networkId) ||
                        completed.questionSetId ===
                          'a600fa90-57cd-4b87-b2df-4765e9cb890d'
                      "
                      (click)="navigateToSurvey(completed.id)"
                      class="btn btn-primary align-self-center"
                    >
                      View <span class="fa fa-eye"></span>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <p
          class="dashboard-list-msg"
          *ngIf="
            !!managerCompletedSurveys &&
            managerCompletedSurveys.length === 0 &&
            !!personalCompletedSurveys &&
            personalCompletedSurveys.length === 0
          "
        >
          <span class="fa fa-info-circle"></span> No recently completed surveys.
        </p>
      </div>
    </div>
  </div>
</section>
