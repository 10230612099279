<div class="error_page" *ngIf="errorData">
  <div class="col-lg-6 col-lg-offset-3">
    <h2>{{ errorData.status }}</h2>
    <h1>{{ errorData.header }}</h1>
    <p class="error-msg" [innerHTML]="errorData.body"></p>
    <a
      *ngIf="errorData.path"
      class="btn btn-finish btn-primary btn_AW_special"
      [attr.href]="errorData.path"
      >{{ errorData.pathText ? errorData.pathText : "Back to Home" }}</a
    >
  </div>
</div>
