import { Hierarchy } from "./hierarchy";

export class FilterOptions {
  public templates: string[];
  public periods: string[];
  public years: number[];
  public managers: Hierarchy[];
  public offices: string[];
  public departments: string[];
}
