import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { FullSurvey, SurveyEntry } from "../models";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable()
export class SurveyService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
  }

  getFullSurvey(surveyId: string, user: string): Observable<FullSurvey> {
    return this.http.get<FullSurvey>(
      environment.apiUrl + "Survey/" + surveyId + "/" + user
    );
  }

  submitSurvey(surveyId: string, survey: SurveyEntry): Observable<boolean> {
    const body = JSON.stringify(survey);
    return this.http.post<boolean>(
      environment.apiUrl + "Survey/" + surveyId,
      body,
      { headers: this.headers }
    );
  }

  saveSurvey(surveyId: string, survey: SurveyEntry): Observable<boolean> {
    const body = JSON.stringify(survey);
    return this.http.post<boolean>(
      environment.apiUrl + "Survey/save/" + surveyId,
      body,
      { headers: this.headers }
    );
  }
}
