<div *ngIf="!!isLoading" awacSpinner></div>
<div class="modal-dialog modal-xlg">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-title">
        Please modify the Survey for <b>{{ surveyItem.assignedToName }}</b>
      </h3>
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <p *ngIf="serverError" class="no_information_message">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        {{
          !invalidUser
            ? "There was an error while trying to retrieve current survey. Please try again later."
            : "You are not authorized to view this survey due to invalid hierarchy level"
        }}
      </p>
      <div *ngIf="!serverError">
        <p
          class="no_information_message"
          *ngIf="
            (!fullSurvey.questions || fullSurvey.questions.length === 0) &&
            !isLoading
          "
        >
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          There are no questions available for current survey.
        </p>
        <div
          class="container"
          *ngIf="fullSurvey.questions && fullSurvey.questions.length"
        >
          <div class="row">
            <div class="col-md-8">
              <h2>{{ surveyItem.title }}</h2>
            </div>
          </div>
          <div id="wizard-progress">
            <ol class="step-indicator">
              <li
                *ngFor="let step of fullSurvey.questions; let i = index"
                [class]="
                  !isLastCompletedQuestion(i) && currentStep === i
                    ? 'active-completed'
                    : currentStep === i
                    ? 'active'
                    : isLastCompletedQuestion(i)
                    ? 'last'
                    : questionCompleted(i)
                    ? 'complete'
                    : ''
                "
              >
                <div class="step" (click)="selectCurrentStep(i)">
                  <i
                    *ngIf="questionCompleted(i) && !isLastCompletedQuestion(i)"
                    class="fa fa-check"
                  ></i>
                </div>
                <div class="caption hidden-xs hidden-sm">{{ i + 1 }}</div>
              </li>
              <li
                [class]="
                  currentStep === fullSurvey.questions.length ? 'active' : ''
                "
              >
                <div class="step"></div>
                <div class="caption hidden-xs hidden-sm">Summary</div>
              </li>
            </ol>
          </div>
          <div
            *ngIf="
              currentStep < fullSurvey.questions.length;
              then show_question;
              else show_summary
            "
          ></div>
          <ng-template #show_question>
            <srvy-question
              [question]="currentQuestion"
              [answer]="currentAnswer"
            ></srvy-question>
          </ng-template>
          <ng-template #show_summary>
            <srvy-summary
              [survey]="survey"
              [questions]="fullSurvey.questions"
            ></srvy-summary>
            <!-- <div class="summaryCheck clearfix">
              <div class="custom-control custom-checkbox"> <input type="checkbox" class="custom-control-input"
                  id="checkSummary1" [(ngModel)]="acknowledge"> <label class="custom-control-label"
                  for="checkSummary1">{{fullSurvey.acknowledge}}</label> </div>
            </div> -->
          </ng-template>
          <div class="btn_options">
            <button
              *ngIf="currentStep > 0"
              class="btn btn-light text-left"
              (click)="setCurrentStep(currentStep - 1)"
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              Back
            </button>
            <div
              *ngIf="
                currentStep >= fullSurvey.questions.length ||
                  (showConditionalQuestion() &&
                    !!currentQuestion.conditionalEndsFlow);
                then show_finish;
                else show_next
              "
            ></div>
            <ng-template #show_next>
              <button
                class="btn btn-success pull-right text-right"
                (click)="setCurrentStep(currentStep + 1)"
                [disabled]="isNextDisabled()"
              >
                Next
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </ng-template>
            <ng-template #show_finish>
              <button
                class="btn btn-success pull-right text-right"
                (click)="submitSurvey()"
                [disabled]="
                  (!true && currentStep >= fullSurvey.questions.length) ||
                  (!!currentQuestion &&
                    ((!!currentQuestion.required &&
                      !currentAnswer.questionAnswer) ||
                      (showConditionalQuestion() &&
                        !!currentQuestion.conditionalQuestion.required &&
                        !currentAnswer.conditionalAnswer)))
                "
              >
                <i class="fa fa-save" aria-hidden="true"></i>
                Update the survey changes
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
