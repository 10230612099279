<div class="error_page">
  <div class="col-lg-6 col-lg-offset-3">
    <h2>404</h2>
    <h1>We couldn't find what you were looking for.</h1>
    <p>
      Unfortunately the page you were looking for couldn't be found. It may be
      temporarily unavailable, moved or no longer exist.
    </p>
    <a class="btn btn-finish btn-primary btn_AW_special" href="dashboard"
      >Back to Home</a
    >
  </div>
</div>
