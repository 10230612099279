import { environment } from "../../environments/environment";
import { OktaConfig } from "@okta/okta-angular/src/okta/models/okta.config";
import { OKTA_CONFIG } from "@okta/okta-angular";

export function getOktaConfig(): OktaConfig {
  return {
    clientId: environment.authenticateApiClientIdOkta,
    issuer: environment.authenticateApiUrlOkta,
    redirectUri: window.location.origin + "/implicit/callback",
    scope: "openid profile email",
    scopes: ["openid", "profile", "email"],
    pkce: false,
  };
}

export const OktaConfigService = {
  provide: OKTA_CONFIG,
  useValue: getOktaConfig(),
};
