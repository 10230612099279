import { Component, OnInit } from "@angular/core";
import { SurveyService } from "./common";
import { Question, Answer, SurveyEntry, FullSurvey } from "./common/models";
import { ActivatedRoute, Router } from "@angular/router";
import { AppAlertsService } from "../shared/modals";
import {
  showAlternativeForQuestion,
  showAlternativeForConditional,
  showConditionalQuestion,
} from "./common/helpers";

@Component({
  selector: "srvy-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.css"],
})
export class SurveyComponent implements OnInit {
  public survey: SurveyEntry = new SurveyEntry();
  public surveyId: string = "";
  public fromSearch: boolean = false;
  public fullSurvey: FullSurvey = new FullSurvey();
  public isLoading: boolean = true;
  public serverError: boolean = false;
  public invalidUser: boolean = false;
  public currentStep: number;
  public currentQuestion: Question = new Question();
  public currentAnswer: Answer = new Answer();
  public showOnly: boolean = false;
  public submitDate: Date;
  public lastCompletedQuestion: number = 0;

  constructor(
    private surveyService: SurveyService,
    private router: Router,
    private route: ActivatedRoute,
    private appAlerts: AppAlertsService
  ) {}

  ngOnInit() {
    this.surveyId = this.route.snapshot.params["surveyId"];
    const user = this.route.snapshot.params["user"];
    this.fromSearch = this.route.snapshot.params["fromSearch"] === "true";
    this.surveyService.getFullSurvey(this.surveyId, user).subscribe(
      (fullSurveyResult: FullSurvey) => {
        this.fullSurvey = fullSurveyResult;
        const answers =
          !!fullSurveyResult.previousAnswers &&
          fullSurveyResult.previousAnswers.length > 0
            ? fullSurveyResult.previousAnswers
            : [];
        this.showOnly = !!this.fullSurvey.submitDate;
        if (!!this.showOnly) {
          this.submitDate = new Date(this.fullSurvey.submitDate);
          this.survey.answers = answers;
        } else {
          this.survey.answers = [];
          for (const question of this.fullSurvey.questions) {
            const answer = answers.filter(
              (a) => a.questionId === question.id
            )[0];
            this.survey.answers.push({
              user: user,
              questionId: question.id,
              questionAnswer: !!answer ? answer.questionAnswer : null,
              conditionalAnswer: !!answer ? answer.conditionalAnswer : null,
              alternativeAnswer: !!answer ? answer.alternativeAnswer : null,
            });
            if (!!answer && !!answer.questionAnswer) {
              this.lastCompletedQuestion++;
            }
          }
        }
        this.setCurrentStep(0);
        this.isLoading = false;
      },
      (error) => {
        if (error.status === 400) {
          this.invalidUser = true;
        }
        this.serverError = true;
      }
    );
  }

  setCurrentStep(step: number): void {
    if (step >= 0) {
      this.currentStep = step;
      this.currentQuestion = this.fullSurvey.questions[this.currentStep];
      this.currentAnswer = this.survey.answers[this.currentStep];
      this.addCompletedQuestion(step);
    }
  }

  selectCurrentStep(step: number): void {
    if (this.questionCompleted(step)) {
      this.setCurrentStep(step);
    }
  }

  submitSurvey(): void {
    this.surveyService.submitSurvey(this.surveyId, this.survey).subscribe(
      (submitResult: boolean) => {
        if (!!submitResult) {
          this.goBack();
        } else {
          this.serverError = true;
        }
      },
      (error) => {
        this.serverError = true;
      }
    );
  }

  saveSurvey(): void {
    this.surveyService.saveSurvey(this.surveyId, this.survey).subscribe(
      (submitResult: boolean) => {
        if (!!submitResult) {
          this.goBack();
        } else {
          this.serverError = true;
        }
      },
      (error) => {
        this.serverError = true;
      }
    );
  }

  showConditionalQuestion(): boolean {
    return showConditionalQuestion(this.currentQuestion, this.currentAnswer);
  }

  showAlternativeQuestion(): boolean {
    return (
      showAlternativeForQuestion(this.currentQuestion, this.currentAnswer) ||
      showAlternativeForConditional(this.currentQuestion, this.currentAnswer)
    );
  }

  isNextDisabled(): boolean {
    const currentDisabled =
      !!this.currentQuestion.required && !this.currentAnswer.questionAnswer;
    const conditionalDisabled =
      showConditionalQuestion(this.currentQuestion, this.currentAnswer) &&
      !!this.currentQuestion.conditionalQuestion.required &&
      !this.currentAnswer.conditionalAnswer;
    const alternativeDisabled =
      showAlternativeForQuestion(this.currentQuestion, this.currentAnswer) &&
      !!this.currentQuestion.alternativeQuestion.required &&
      !this.currentAnswer.alternativeAnswer;
    const alternativeConditionalDisabled =
      showAlternativeForConditional(this.currentQuestion, this.currentAnswer) &&
      !!this.currentQuestion.conditionalQuestion.alternativeQuestion.required &&
      !this.currentAnswer.alternativeAnswer;

    return (
      !!currentDisabled ||
      !!conditionalDisabled ||
      !!alternativeDisabled ||
      !!alternativeConditionalDisabled
    );
  }

  goBack(): void {
    this.router.navigate([this.fromSearch ? "/search" : "/dashboard"]);
  }

  backToDashboard(): void {
    const answersAlreadySet = this.survey.answers.filter((answer) => {
      return answer.questionAnswer != null && answer.questionAnswer !== "";
    }).length;

    if (answersAlreadySet > 0) {
      this.appAlerts
        .confirm(
          "Back to Dashboard",
          "You have unsubmitted changes. Are you sure you want to exit the survey?",
          "Yes",
          "No"
        )
        .subscribe((result) => {
          if (!!result) {
            this.goBack();
          }
        });
    } else {
      this.goBack();
    }
  }

  questionCompleted(step: number): boolean {
    return this.lastCompletedQuestion >= step;
  }

  isLastCompletedQuestion(step: number): boolean {
    return this.lastCompletedQuestion === step;
  }

  addCompletedQuestion(step: number): void {
    if (!this.questionCompleted(step)) {
      this.lastCompletedQuestion = step;
    }
  }
}
