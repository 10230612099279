import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  SurveyComponent,
  SurveyService,
  QuestionComponent,
  SummaryComponent,
} from ".";
import { SharedModule } from "../shared/shared.module";
import { SurveyMockService } from "./common/services/survey-mock.service";
@NgModule({
  declarations: [SurveyComponent, QuestionComponent, SummaryComponent],
  imports: [CommonModule, FormsModule, BrowserModule, SharedModule],
  providers: [SurveyService],
  exports: [SurveyComponent, QuestionComponent, SummaryComponent],
})
export class SurveyModule {}
