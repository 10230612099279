import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ChartsModule } from "ng2-charts";
import { AngularMyDatePickerModule } from "angular-mydatepicker";
import { OrderModule } from "ngx-order-pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { NgSelectModule } from "@ng-select/ng-select";

import { SurveyModule } from "../survey/survey.module";

import {
  DashboardComponent,
  DashboardService,
  SurveySearchComponent,
  MetricsComponent,
  MySurveysComponent,
  ExtendComponent,
  AdministrationComponent,
} from ".";
import { SharedModule } from "../shared/shared.module";
import { DashboardMockService } from "./common/services/dashboard-mock.service";
import { DelegateModalComponent } from "./modals/delegate-modal/delegate-modal.component";
import { DuedateModalComponent } from "./modals/duedate-modal/duedate-modal.component";
import { ReopenModalComponent } from "./modals/reopen-modal/reopen-modal.component";
import { DelegateComponent } from "./administration/delegate/delegate.component";
import { ReopenAndFixComponent } from "./administration/reopen-and-fix/reopen-and-fix.component";
import { EmployeeListComponent } from "./modals/employee-list/employee-list.component";
@NgModule({
  declarations: [
    DashboardComponent,
    MetricsComponent,
    MySurveysComponent,
    SurveySearchComponent,
    AdministrationComponent,
    DelegateModalComponent,
    DuedateModalComponent,
    ReopenModalComponent,
    ExtendComponent,
    DelegateComponent,
    ReopenAndFixComponent,
    EmployeeListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserModule,
    SharedModule,
    ChartsModule,
    AngularMyDatePickerModule,
    SurveyModule,
    OrderModule,
    NgxPaginationModule,
    NgSelectModule,
  ],
  providers: [DashboardService],
  entryComponents: [
    DelegateModalComponent,
    DuedateModalComponent,
    ReopenModalComponent,
    EmployeeListComponent,
  ],
})
export class DashboardModule {}
