import {
  Component,
  ViewChild,
  ComponentFactoryResolver,
  ReflectiveInjector,
  Type,
} from "@angular/core";
import { DialogComponent } from "./dialog.component";
import { DialogBaseComponent } from "./dialog-base.component";

@Component({
  selector: "srvy-dialog-wrapper",
  template: `
    <div
      #container
      class="modal fade"
      style="display:block !important;"
      role="dialog"
    >
      <template #element></template>
    </div>
  `,
})
export class DialogWrapperComponent extends DialogBaseComponent {
  /**
   * Link container DOM element
   */
  @ViewChild("container", { static: false }) public container;

  /**
   * Dialog content componet
   * @param <DialogComponent>
   */
  private content: DialogComponent<any, any>;

  /**
   * Constructor
   * @param <ComponentFactoryResolver> resolver
   * @param <DialogService> dialogService
   */
  constructor(private resolver: ComponentFactoryResolver) {
    super();
  }

  /**
   * Adds content dialog component to wrapper
   * @param <Type<DialogComponent>> component
   * @return <DialogComponent>
   */
  addComponent<T, T1>(component: Type<DialogComponent<T, T1>>) {
    const factory = this.resolver.resolveComponentFactory(component);
    // tslint:disable-next-line deprecation
    const injector = ReflectiveInjector.fromResolvedProviders(
      [],
      this.element.injector
    );
    const componentRef = factory.create(injector);
    this.element.insert(componentRef.hostView);
    this.content = <DialogComponent<T, T1>>componentRef.instance;
    this.content.wrapper = this;
    return this.content;
  }

  /**
   * Registers event handler to close dialog by click on backdrop
   */
  closeByClickOutside() {
    const containerEl = this.container.nativeElement;
    containerEl
      .querySelector(".modal-content")
      .addEventListener("click", (event) => {
        event.stopPropagation();
      });
    containerEl.addEventListener(
      "click",
      () => {
        this.content.close();
      },
      false
    );
  }
}
