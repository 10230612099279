import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../auth";
import { User, UserRole } from "../../../auth/models";
import { DashboardService } from "../../common";
import { FilterOptions, SelectedOptions, Hierarchy } from "../../common/models";
import { assignOptionsAndDefaults } from "../../common/helpers";
import { FullSurvey } from "src/app/survey/common/models";

@Component({
  selector: "srvy-survey-search",
  templateUrl: "./survey-search.component.html",
  styleUrls: ["./survey-search.component.css"],
})
export class SurveySearchComponent implements OnInit {
  public surveys: FullSurvey[] = [];
  public selectedManager: Hierarchy;
  public isLoading: boolean = true;
  public isLoadingSearch: boolean = false;
  public serverError: boolean = false;
  public loggedUser: string;
  public managerAccess: boolean = false;
  public hrAccess: boolean = false;
  public filterOptions: FilterOptions = new FilterOptions();
  public selectedOptions: SelectedOptions = new SelectedOptions();

  constructor(
    private authService: AuthenticationService,
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.getUserData().subscribe((user: User) => {
      if (!user || !user.networkId) {
        this.serverError = true;
      } else {
        this.loggedUser = user.networkId;
        this.dashboardService.getUserRole(this.loggedUser).subscribe(
          (roleResult: UserRole) => {
            this.managerAccess =
              roleResult === UserRole.HrManager ||
              roleResult === UserRole.Manager ||
              roleResult === UserRole.ManagerOfManagers;
            this.hrAccess =
              roleResult === UserRole.HrEmployee ||
              roleResult === UserRole.HrManager;
            if (this.hrAccess) {
              this.getHrFilters();
            } else {
              this.getFilters();
            }
          },
          (error) => {
            this.serverError = true;
            this.isLoading = false;
          }
        );
      }
    });
  }

  getFilters(): void {
    this.isLoading = true;
    this.dashboardService.getFilterOptions(this.loggedUser).subscribe(
      (optionsResponse: FilterOptions) => {
        this.filterOptions = optionsResponse;
        this.selectedOptions = assignOptionsAndDefaults(
          this.filterOptions,
          false,
          false
        );
        this.selectedManager =
          !!this.filterOptions && !!this.filterOptions.managers
            ? this.filterOptions.managers.filter(
                (x) => x.employeeName === this.selectedOptions.manager
              )[0]
            : new Hierarchy();
        this.isLoading = false;
        this.searchSurveys();
      },
      (error) => {
        this.serverError = true;
        this.isLoading = false;
      }
    );
  }

  getHrFilters(): void {
    this.isLoading = true;
    this.dashboardService.getHrFilterOptions().subscribe(
      (optionsResponse: FilterOptions) => {
        this.filterOptions = optionsResponse;
        this.selectedOptions = assignOptionsAndDefaults(
          this.filterOptions,
          false,
          false
        );
        this.selectedManager =
          !!this.filterOptions && !!this.filterOptions.managers
            ? this.filterOptions.managers.filter(
                (x) => x.employeeName === this.selectedOptions.manager
              )[0]
            : new Hierarchy();
        this.isLoading = false;
        this.searchSurveys();
      },
      (error) => {
        this.serverError = true;
        this.isLoading = false;
      }
    );
  }

  searchSurveys(): void {
    this.isLoadingSearch = true;
    const selected: SelectedOptions = {
      template: this.selectedOptions.template,
      manager: null,
      employee: null,
      office: null,
      department: null,
      period: this.selectedOptions.period,
      year: this.selectedOptions.year,
    };
    if (!this.myConnectSelected()) {
      selected.manager = this.selectedOptions.manager;
      selected.employee = this.selectedOptions.employee;
      selected.office = this.selectedOptions.office;
      selected.department = this.selectedOptions.department;
    }

    this.dashboardService.searchSurveys(selected, this.loggedUser).subscribe(
      (searchResult: FullSurvey[]) => {
        this.surveys = searchResult || [];
        this.isLoadingSearch = false;
      },
      (error) => {
        this.serverError = true;
        this.isLoadingSearch = false;
      }
    );
  }

  myConnectSelected(): boolean {
    return this.selectedOptions.template === "My Connect";
  }

  onManagerChange(manager: Hierarchy): void {
    this.selectedManager = manager;
    if (!manager) {
      delete this.selectedOptions.manager;
    } else {
      this.selectedOptions.manager = manager.networkId;
    }

    delete this.selectedOptions.employee;
  }

  backToHome(): void {
    this.router.navigate(["/"]);
  }

  navigateToSurvey(surveyId: string, user: string): void {
    this.router.navigate(["/survey", surveyId, user, "true"]);
  }
}
