import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DashboardService } from "./common";
import {
  SurveyHeaders,
  FilterOptions,
  Metrics,
  SelectedOptions,
  ViewAs,
} from "./common/models";
import { AuthenticationService } from "../auth";
import { User, UserRole } from "../auth/models";
import { assignOptionsAndDefaults } from "./common/helpers";
import { GlobalConstants } from "../shared/global-constants";

@Component({
  selector: "srvy-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  public surveyHeaders: SurveyHeaders = new SurveyHeaders();
  public metrics: Metrics = new Metrics();
  public hrMetrics: Metrics = new Metrics();
  public loggedUser: ViewAs = new ViewAs();
  public selectedUser: ViewAs = new ViewAs();
  public isLoadingSurveys: boolean = false;
  public isLoadingMetrics: boolean = false;
  public isLoadingHrMetrics: boolean = false;
  public serverError: boolean = false;
  public active: string = "surveys";
  public filterOptions: FilterOptions = new FilterOptions();
  public hr_filterOptions: FilterOptions = new FilterOptions();
  public selectedOptions: SelectedOptions = new SelectedOptions();
  public hr_selectedOptions: SelectedOptions = new SelectedOptions();

  public isLoadingCredentials: boolean = true;
  public allowMetrics: boolean = false;
  public allowHrMetrics: boolean = false;
  public allowHrAdministration: boolean = false;
  constructor(
    private dashboardService: DashboardService,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.getUserData().subscribe((user: User) => {
      if (!user || !user.networkId) {
        this.serverError = true;
      } else {
        this.loggedUser.networkId = user.networkId;
        this.loggedUser.name = user.displayName;
        this.selectedUser.networkId = user.networkId;
        this.selectedUser.name = user.displayName;
        this.getUserRole();
      }
    });
  }
  /**
   * @description Server call to get user Role
   */
  getUserRole() {
    this.isLoadingCredentials = true;
    this.dashboardService
      .getUserRole(this.loggedUser.networkId)
      .subscribe((roleResult: UserRole) => {
        this.isLoadingCredentials = false;
        if (roleResult || roleResult === 0) {
          this.getSurveys();
          this.setDashboardPermission(roleResult);
        } else {
          const errorData = {
            path: "",
            pathText: "",
            status: 401,
            header: GlobalConstants.HTTP_STATUS_CONSTANTS[401],
            body: "You are unauthorized to visit this application. Please contact your administrator.\n<i> Report the error with : User role not found</i>",
          };
          sessionStorage.setItem(
            GlobalConstants.API_ERROR_SESSIONSTORAGE_KEY,
            JSON.stringify(errorData)
          );
          this.router.navigate(["serverError"]);
        }
      });
  }
  /**
   * @description set dashboard navigation tab permission flags for the userRole data
   * @param roleResult <enum{ManagerOfManagers = 0,Employee = 1,Manager = 2,HrManager = 3,HrEmployee = 4}>
   */
  setDashboardPermission(roleResult: UserRole) {
    this.allowMetrics =
      roleResult === UserRole.HrManager ||
      roleResult === UserRole.Manager ||
      roleResult === UserRole.ManagerOfManagers;
    if (!!this.allowMetrics) {
      this.getMetrics();
    }
    this.allowHrMetrics =
      roleResult === UserRole.HrEmployee || roleResult === UserRole.HrManager;
    if (this.allowHrMetrics) {
      this.getHrMetrics();
    }
    this.allowHrAdministration =
      roleResult === UserRole.HrManager || roleResult === UserRole.HrEmployee;
  }

  getSurveys(): void {
    this.isLoadingSurveys = true;
    this.dashboardService
      .getSurveys(this.selectedUser.networkId)
      .subscribe((surveyResponse: SurveyHeaders) => {
        this.surveyHeaders = surveyResponse;
        this.isLoadingSurveys = false;
      });
  }

  getMetrics(): void {
    this.isLoadingMetrics = true;
    this.dashboardService
      .getFilterOptions(this.loggedUser.networkId)
      .subscribe((optionsResponse: FilterOptions) => {
        this.filterOptions = optionsResponse;
        this.selectedOptions = assignOptionsAndDefaults(
          this.filterOptions,
          true,
          true
        );
        if (!this.filterOptions.templates || !this.filterOptions.years) {
          this.isLoadingMetrics = false;
        } else {
          this.getFilteredMetrics(this.selectedOptions);
        }
      });
  }

  getHrMetrics(): void {
    this.isLoadingHrMetrics = true;
    this.dashboardService
      .getHrFilterOptions()
      .subscribe((optionsResponse: FilterOptions) => {
        this.hr_filterOptions = optionsResponse;
        this.isLoadingHrMetrics = false;
        this.hr_selectedOptions = assignOptionsAndDefaults(
          this.hr_filterOptions,
          true,
          true
        );
        if (this.hr_filterOptions.templates && this.hr_filterOptions.years) {
          this.isLoadingHrMetrics = true;
          this.getHrFilteredMetrics(this.hr_selectedOptions);
        }
      });
  }

  getFilteredMetrics(selected: SelectedOptions) {
    this.isLoadingMetrics = true;
    this.dashboardService
      .getMetrics(selected, selected.manager || this.loggedUser.networkId)
      .subscribe((metricsResponse: Metrics) => {
        this.metrics = metricsResponse;
        this.isLoadingMetrics = false;
      });
  }

  getHrFilteredMetrics(selected: SelectedOptions) {
    this.isLoadingHrMetrics = true;
    this.dashboardService
      .getHrMetrics(selected)
      .subscribe((metricsResponse: Metrics) => {
        this.hrMetrics = metricsResponse;
        this.isLoadingHrMetrics = false;
      });
  }

  goBackToMyDashboard(user: ViewAs) {
    this.selectedUser = user;
    this.getSurveys();
  }
}
