export class GlobalConstants {
  public static DEFAULT_PAGE = "/";
  public static MODAL_BACKGROUND_COLOR = "rgba(0, 0, 0, 0.5)";
  public static MAX_ELEMENTS_CLAIM_NOTES = 3;
  public static MIN_CHAR_SEARCHTEXT = 3;
  public static SEARCH_VALID_REGEX = /^[ a-zA-Z0-9/-]*$/;
  public static TEXT_VALID_REGEX = /^[ a-zA-Z0-9,.@_\-()&*!]*$/;
  public static PHONE_VALID_REGEX = /^[ 0-9-]*$/;
  // tslint:disable-next-line:max-line-length
  public static EMAIL_VALID_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static YEAR_VALID_REGEX = /^\d{2,4}$/;
  public static OBJECT_TYPES = {
    policy: 1,
  };
  public static INTERNET_EXPLORER_LOCAL_STORAGE = "awconnect_allowie";
  public static UNSUPPORTED_BROWSER_URL = "/unsupported_browser";
  public static ADMINISTRATION_LOCALSTORAGE_KEY = "administration";
  public static API_ERROR_SESSIONSTORAGE_KEY = "api_error-data";
  public static HTTP_STATUS_CONSTANTS = {
    401: "You are unauthorized",
    404: "We couldn't find this operation",
    408: "Request Timeout",
    500: "We couldn't process this request right now",
    502: "Bad Gateway",
    504: "Gateway Timeout",
  };
}
