import { Directive, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { Spinner } from "spin.js";

@Directive({
  selector: "[awacSpinner]",
})
export class SpinnerDirective implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input("awacSpinner") inlineOrientation: string = "";
  private colorTheme = "#b31b34";
  public spinner;
  constructor(private elementRef: ElementRef) {}
  ngOnInit() {
    let opts = null;
    switch (this.inlineOrientation) {
      case "": {
        opts = {
          lines: 13, // The number of lines to draw
          length: 20, // The length of each line
          width: 8, // The line thickness
          radius: 30, // The radius of the inner circle
          scale: 0.4, // Scales overall size of the spinner
          corners: 1, // Corner roundness (0..1)
          color: this.colorTheme, // #rgb or #rrggbb or array of colors
          opacity: 0.25, // Opacity of the lines
          rotate: 0, // The rotation offset
          direction: 1, // 1: clockwise, -1: counterclockwise
          speed: 1, // Rounds per second
          trail: 60, // Afterglow percentage
          fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
          zIndex: 2e9, // The z-index (defaults to 2000000000)
          className: "spinner", // The CSS class to assign to the spinner
          top: "50%", // Top position relative to parent
          left: "50%", // Left position relative to parent
          shadow: false, // Whether to render a shadow
          hwaccel: true, // Whether to use hardware acceleration
          position: "fixed", // Element positioning
        };
        break;
      }
      case "inline-spinner-left": {
        opts = {
          scale: 0.5,
          left: "10px",
          top: "48px",
          className: this.inlineOrientation,
          color: this.colorTheme,
        };
        break;
      }
      case "inline-field-spinner": {
        opts = {
          scale: 0.5,
          left: "16px",
          top: "16px",
          className: this.inlineOrientation,
          color: this.colorTheme,
        };
        break;
      }
      default: {
        opts = {
          scale: 1.5,
          lines: 11,
          length: 3,
          width: 2,
          radius: 4,
          className: this.inlineOrientation, // The CSS class to assign to the spinner
          color: this.colorTheme,
        };
        break;
      }
    }

    this.spinner = new Spinner(opts);
    this.spinner.spin(this.elementRef.nativeElement);
  }
  ngOnDestroy() {
    this.spinner.stop();
  }
}
