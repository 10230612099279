import { Component } from "@angular/core";
import { DialogComponent } from "../ng2-bootstrap-modal";

export interface AlertModel {
  title: string;
  message: string;
  okButton?: string;
  isError?: boolean;
}

@Component({
  selector: "srvy-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.css"],
})
export class AlertComponent
  extends DialogComponent<AlertModel, boolean>
  implements AlertModel
{
  public title: string;
  public message: string;
  public okButton: string;
  public isError: boolean = true;

  constructor() {
    super();
  }

  closeModal(result: boolean) {
    this.result = result;
    this.close();
  }
}
