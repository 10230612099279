import { Component, OnInit, Input } from "@angular/core";
import { Question, Answer } from "../common/models";
import {
  showAlternativeForQuestion,
  showAlternativeForConditional,
  showConditionalQuestion,
} from "../common/helpers";

@Component({
  selector: "srvy-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.css"],
})
export class QuestionComponent implements OnInit {
  @Input() question: Question = new Question();
  @Input() answer: Answer = new Answer();

  constructor() {}

  ngOnInit() {}

  optionChange(value: string): void {
    this.answer.questionAnswer = value;
    if (
      !!this.question.conditionalQuestion &&
      !!this.question.displayForOptions &&
      !this.question.displayForOptions.filter(
        (x) => x === this.answer.questionAnswer
      )[0]
    ) {
      this.answer.conditionalAnswer = null;
    }

    if (
      !!this.question.alternativeQuestion &&
      !!this.question.displayForOptions &&
      !!this.question.displayForOptions.filter(
        (x) => x === this.answer.questionAnswer
      )[0]
    ) {
      this.answer.alternativeAnswer = null;
    }
  }

  conditionalOptionChange(value: string): void {
    this.answer.conditionalAnswer = value;
  }

  alternativeOptionChange(value: string): void {
    this.answer.alternativeAnswer = value;
  }

  showConditionalQuestion(): boolean {
    return showConditionalQuestion(this.question, this.answer);
  }

  showAlternativeQuestion(): boolean {
    return (
      showAlternativeForQuestion(this.question, this.answer) ||
      showAlternativeForConditional(this.question, this.answer)
    );
  }
}
