import { Component } from "@angular/core";

@Component({
  selector: "srvy-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "awconnect";

  constructor() {}
}
