import { Component, OnInit } from "@angular/core";
import { DialogService } from "src/app/shared/modals//ng2-bootstrap-modal";
import { DelegateModalComponent } from "../../modals/delegate-modal/delegate-modal.component";
import { EmployeeListComponent } from "../../modals/employee-list/employee-list.component";
import { ToastrService } from "ngx-toastr";

import { DashboardService } from "../../common/services/dashboard.service";
import { Delegation } from "../../common/models";
@Component({
  selector: "srvy-delegate",
  templateUrl: "./delegate.component.html",
  styleUrls: ["./delegate.component.css"],
})
export class DelegateComponent implements OnInit {
  public isLoading: boolean;
  public existingDelegation: Delegation[] = [];
  public serverError: boolean;
  public searchText: string;
  constructor(
    private dialogService: DialogService,
    private dashboardService: DashboardService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.getExistingDelegation();
  }
  /**
   * @description Fetch list of active existing delegation
   */
  getExistingDelegation() {
    this.isLoading = true;
    this.dashboardService
      .getExistingDelegation()
      .subscribe((data: Delegation[]) => {
        this.isLoading = false;
        this.existingDelegation = data;
      });
  }
  /**
   * @description Add new delegations
   */
  openDelegatePopup() {
    this.dialogService
      .addDialog(DelegateModalComponent, null)
      .subscribe((result) => {
        if (result) {
          this.getExistingDelegation();
        }
      });
  }
  /**
   * @description Stop active delegation
   * @param delegation <individual delegation>
   */
  stopDelegationClick(delegation: Delegation) {
    this.dialogService
      .addDialog(EmployeeListComponent, { delegation, stop: true })
      .subscribe((result) => {
        if (result) {
          this.stopDelegation(delegation);
        }
      });
  }
  /**
   * @description Stop active delegation
   * @param delegation <individual delegation>
   */
  stopDelegation(delegation: Delegation) {
    this.isLoading = true;
    const employeeList = delegation.directReports.map((item) => {
      return item.networkId;
    });
    this.dashboardService
      .stopDelegation(employeeList)
      .subscribe((data: boolean) => {
        this.isLoading = false;
        if (data) {
          this.getExistingDelegation();
        } else {
          this.toastrService.error("Something went wrong", "Error!!");
        }
      });
  }
  /**
   * @description Show details of delegation
   * @param delegation <individual delegation>
   */
  openMoreInfo(delegation: Delegation) {
    this.dialogService.addDialog(EmployeeListComponent, {
      delegation,
      stop: false,
    });
  }
}
