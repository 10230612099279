<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title || "Confirm" }}</h4>
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
    <div class="modal-body">
      <p>{{ message || "Are you sure you want to proceed?" }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="confirm()">
        <i class="fa fa-check" aria-hidden="true"></i> {{ okButton || "Ok" }}
      </button>
      <button type="button" class="btn btn-danger" (click)="cancel()">
        <i class="fa fa-times" aria-hidden="true"></i>
        {{ cancelButton || "Cancel" }}
      </button>
    </div>
  </div>
</div>
