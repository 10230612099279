<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title || "Alert" }}</h4>
      <button type="button" class="close" (click)="closeModal(false)">
        &times;
      </button>
    </div>
    <div class="modal-body">
      <p [class.no_information_message]="isError">
        <i
          *ngIf="isError"
          class="fa fa-exclamation-triangle"
          aria-hidden="true"
        ></i>
        {{ message || "Error" }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-danger pull-right"
        (click)="closeModal(true)"
      >
        <i class="fa fa-times" aria-hidden="true"></i> {{ okButton || "Close" }}
      </button>
    </div>
  </div>
</div>
