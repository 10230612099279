import { Component } from "@angular/core";
import { DialogComponent } from "../ng2-bootstrap-modal";

export interface ConfirmModel {
  title: string;
  message: string;
  okButton: string;
  cancelButton: string;
}

@Component({
  selector: "srvy-confirm",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.css"],
})
export class ConfirmComponent
  extends DialogComponent<ConfirmModel, boolean>
  implements ConfirmModel
{
  public title: string;
  public message: string;
  public okButton: string;
  public cancelButton: string;
  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }
}
