<div awacSpinner *ngIf="!!isLoading"></div>
<div *ngIf="!!serverError; then invalid_parameters; else show_surveys"></div>
<ng-template #invalid_parameters>
  <p
    class="no_information_message pointer"
    (click)="office ? getExtendableSurveys() : getOfficeList"
  >
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    There was an error while trying to retrieve document list.
    <u>Please try again</u>
  </p>
</ng-template>
<ng-template #show_surveys>
  <div class="row">
    <div class="col-12 col-lg-12">
      <p class="info">
        *Select survey(s) and click on the Extend Due Date button at the bottom
        to continue.
      </p>
      <div class="row filter">
        <div class="col-2 col-lg-2">
          <div class="form-group">
            <label for="formOffice">Office</label>
            <select
              class="form-control"
              id="formOffice"
              (change)="filterChanged()"
              name="formOffice"
              [(ngModel)]="office"
            >
              <option value="" *ngIf="!office">Select</option>
              <option
                *ngFor="let office of officeList"
                [ngValue]="office ? office : 'unknown'"
              >
                {{ office ? office : "Unknown" }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-2 col-lg-2">
          <div class="form-group">
            <label for="type">Survey type</label>
            <select
              class="form-control"
              id="type"
              (change)="filterChanged()"
              name="type"
              [(ngModel)]="surveyType"
            >
              <option value="2">Manager Survey</option>
              <option value="1">Employee Survey</option>
            </select>
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <label for="type"> </label>
          <div class="form-group">
            <input
              type="search"
              class="form-control"
              [attr.placeholder]="
                'Search ' + (surveyType == 1 ? 'Employee Name' : 'Manager Name')
              "
              [(ngModel)]="searchText"
            />
          </div>
        </div>
        <div class="col-4 col-lg-4">
          <div class="show_pagination">
            Show
            <button
              type="button"
              class="btn btn-light btn-sm"
              [class.active]="maxItemsPerPage === 10"
              (click)="setPaging(10)"
            >
              10
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              [class.active]="maxItemsPerPage === 20"
              (click)="setPaging(20)"
            >
              20
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              [class.active]="maxItemsPerPage === 50"
              (click)="setPaging(50)"
            >
              50
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              [class.active]="maxItemsPerPage === 100"
              (click)="setPaging(100)"
            >
              100
            </button>
            <button
              type="button"
              class="btn btn-light btn-sm"
              [class.active]="maxItemsPerPage > 100"
              (click)="setPaging(200)"
            >
              All
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="card">
            <div class="tableCont">
              <table class="table mar-b-0">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <a
                        (click)="sortType('assignedToName')"
                        class="sort_caret"
                      >
                        {{ surveyType == 1 ? "Employee Name" : "Manager Name" }}
                        <span
                          *ngIf="sortProp !== 'assignedToName'"
                          class="placeholder_caret"
                        ></span>
                        <span
                          *ngIf="sortProp === 'assignedToName' && !sortReverse"
                          class="fa fa-caret-down"
                        ></span>
                        <span
                          *ngIf="sortProp === 'assignedToName' && sortReverse"
                          class="fa fa-caret-up"
                        ></span>
                      </a>
                    </th>
                    <th *ngIf="surveyType == 2">
                      <a
                        class="sort_caret curser-default"
                        (click)="sortType('targetName')"
                      >
                        Employee Name
                        <span
                          *ngIf="sortProp !== 'targetName'"
                          class="placeholder_caret"
                        ></span>
                        <span
                          *ngIf="sortProp === 'targetName' && !sortReverse"
                          class="fa fa-caret-down"
                        ></span>
                        <span
                          *ngIf="sortProp === 'targetName' && sortReverse"
                          class="fa fa-caret-up"
                        ></span>
                      </a>
                    </th>
                    <th>
                      <a (click)="sortType('endDate')" class="sort_caret">
                        Due Date
                        <span
                          *ngIf="sortProp !== 'endDate'"
                          class="placeholder_caret"
                        ></span>
                        <span
                          *ngIf="sortProp === 'endDate' && !sortReverse"
                          class="fa fa-caret-down"
                        ></span>
                        <span
                          *ngIf="sortProp === 'endDate' && sortReverse"
                          class="fa fa-caret-up"
                        ></span>
                      </a>
                    </th>
                    <th>
                      <a
                        (click)="sortType('extendedDueDate')"
                        class="sort_caret"
                      >
                        Extended Date
                        <span
                          *ngIf="sortProp !== 'extendedDueDate'"
                          class="placeholder_caret"
                        ></span>
                        <span
                          *ngIf="sortProp === 'extendedDueDate' && !sortReverse"
                          class="fa fa-caret-down"
                        ></span>
                        <span
                          *ngIf="sortProp === 'extendedDueDate' && sortReverse"
                          class="fa fa-caret-up"
                        ></span>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let quarter of extendableSurveys">
                    <tr class="tr-head">
                      <td>
                        <div
                          class="custom-control custom-checkbox"
                          title="Apply to all surveys under {{
                            quarter.quarterName
                          }}"
                        >
                          <input
                            (change)="checkAll(quarter)"
                            [(ngModel)]="quarter.check"
                            class="custom-control-input"
                            id="check-{{ quarter.quarterName }}{{ surveyType }}"
                            type="checkbox"
                          />
                          <label
                            class="custom-control-label"
                            for="check-{{ quarter.quarterName }}{{
                              surveyType
                            }}"
                          ></label>
                        </div>
                      </td>
                      <td [attr.colspan]="surveyType == 1 ? 3 : 4">
                        <b>
                          {{ surveyType == 1 ? "Employee" : "Manager" }} Survey
                          for {{ quarter.quarterName }}</b
                        >
                        <a
                          (click)="expandQuarter(quarter)"
                          title="{{ quarter.show ? 'Collapse' : 'Expand' }}"
                        >
                          <span class="pull-right"
                            ><i
                              class="fa"
                              [class.fa-chevron-down]="!quarter.show"
                              [class.fa-chevron-up]="quarter.show"
                              aria-hidden="true"
                            ></i
                          ></span>
                        </a>
                      </td>
                    </tr>
                    <ng-container *ngIf="quarter.show">
                      <tr
                        *ngFor="
                          let survey of quarter.surveys
                            | filter: { assignedToName: searchText }
                            | orderBy: [sortProp]:sortReverse:true
                            | paginate
                              : {
                                  itemsPerPage: maxItemsPerPage,
                                  currentPage: pageNumber
                                };
                          let i = index
                        "
                      >
                        <td>
                          <div
                            class="custom-control custom-checkbox"
                            *ngIf="quarter.surveys && quarter.surveys.length"
                          >
                            <input
                              class="custom-control-input"
                              id="survey{{ quarter.quarterName + i }}"
                              [(ngModel)]="survey.check"
                              type="checkbox"
                              (change)="selectChange(survey, quarter)"
                            />
                            <label
                              class="custom-control-label"
                              for="survey{{ quarter.quarterName + i }}"
                            ></label>
                          </div>
                        </td>
                        <td [attr.title]="survey.title">
                          {{ survey.assignedToName }}
                        </td>
                        <td *ngIf="surveyType == 2">{{ survey.targetName }}</td>
                        <td>{{ survey.endDate | date: "dd MMM  yyyy" }}</td>
                        <td
                          [attr.title]="
                            survey.extendedDueDate && survey.comments
                              ? 'Extension Comment : ' + survey.comments
                              : ''
                          "
                        >
                          {{ survey.extendedDueDate | date: "dd MMM  yyyy" }}
                        </td>
                      </tr>
                      <tr *ngIf="!quarter.surveys || !quarter.surveys.length">
                        <td [attr.colspan]="surveyType == 1 ? 4 : 5">
                          Extendable
                          {{ surveyType == 1 ? "Employee" : "Manager" }}
                          Survey(s) not found for
                          {{ quarter.quarterName }}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <tr *ngIf="!extendableSurveys.length">
                    <td [attr.colspan]="surveyType == 1 ? 4 : 5">
                      {{
                        isLoading
                          ? "Loading..."
                          : "Extendable " +
                            (surveyType == 1 ? "Employee" : "Manager") +
                            " Survey(s) not found"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <pagination-controls (pageChange)="pageNumber = $event">
          </pagination-controls>
        </div>
      </div>
      <button
        class="separation btn btn-primary btn-lg btnRed float-right"
        [disabled]="!enableExtendButton"
        (click)="openDue()"
      >
        Extend Due Date
      </button>
    </div>
  </div>
</ng-template>
