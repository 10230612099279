import { Question, Answer } from "../models";

export function showAlternativeForQuestion(
  question: Question,
  answer: Answer
): boolean {
  const display =
    !!answer.questionAnswer &&
    (!question.displayForOptions ||
      (!!question.displayForOptions &&
        question.displayForOptions.filter((x) => x === answer.questionAnswer)
          .length === 0));

  return !!display && !!question && !!question.alternativeQuestion;
}

export function showAlternativeForConditional(
  question: Question,
  answer: Answer
): boolean {
  const displayForConditional =
    !!answer.conditionalAnswer &&
    !!question.conditionalQuestion &&
    (!question.conditionalQuestion.displayForOptions ||
      (!!question.conditionalQuestion &&
        question.conditionalQuestion.displayForOptions.filter(
          (x) => x === answer.conditionalAnswer
        ).length === 0));

  return (
    !!displayForConditional &&
    !!question &&
    !!question.conditionalQuestion &&
    !!question.conditionalQuestion.alternativeQuestion
  );
}

export function showConditionalQuestion(
  question: Question,
  answer: Answer
): boolean {
  const display =
    !!question.displayForOptions &&
    !!question.displayForOptions.filter((x) => x === answer.questionAnswer)[0];
  return !!display && !!question.conditionalQuestion;
}
