<div *ngIf="!!isLoadingSurveys || isLoadingCredentials" awacSpinner></div>
<ng-container *ngIf="!isLoadingCredentials">
  <div *ngIf="!serverError; then show_widgets; else show_error"></div>
  <ng-template #show_error>
    <p class="no_information_message">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      {{
        !!loggedUser.networkId
          ? "There was an error while trying to retrieve active and completed surveys. Please try again later."
          : "There was an error while trying to retrieve user credentials. Please try again later."
      }}
    </p>
  </ng-template>
  <ng-template #show_widgets>
    <nav class="mainTabs">
      <div class="container">
        <div class="row">
          <div class="col">
            <ul class="nav nav-tabs row">
              <li class="nav-item col" (click)="active = 'surveys'">
                <a
                  [class]="'nav-link ' + (active === 'surveys' ? 'active' : '')"
                >
                  <i class="fa fa-user"></i>
                  My surveys
                </a>
              </li>
              <li
                *ngIf="!!allowMetrics"
                class="nav-item col"
                (click)="active = 'metrics'"
              >
                <a
                  [class]="'nav-link ' + (active === 'metrics' ? 'active' : '')"
                >
                  <i class="fa fa-pie-chart"></i>
                  Metrics
                </a>
              </li>
              <li
                *ngIf="!!allowHrMetrics"
                class="nav-item col"
                (click)="active = 'hr_metrics'"
              >
                <a
                  [class]="
                    'nav-link ' + (active === 'hr_metrics' ? 'active' : '')
                  "
                >
                  <i class="fa fa-pie-chart"></i>
                  HR Metrics
                </a>
              </li>
              <li
                *ngIf="!!allowHrMetrics"
                class="nav-item col"
                (click)="active = 'admin'"
              >
                <a [class]="'nav-link ' + (active === 'admin' ? 'active' : '')">
                  <i class="fa fa-graduation-cap"></i>
                  Administration
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mainTabsLine"></div>
    </nav>
    <div class="tab-content">
      <div
        [class]="'tab-pane fade ' + (active === 'surveys' ? 'show active' : '')"
        id="surveys"
        role="tabpanel"
      >
        <srvy-my-surveys
          [surveyHeaders]="surveyHeaders"
          [selectedUser]="selectedUser"
          [loggedUser]="loggedUser"
          (backToDashbordEvent)="goBackToMyDashboard($event)"
        ></srvy-my-surveys>
      </div>
      <div
        *ngIf="!!allowMetrics"
        [class]="'tab-pane fade ' + (active === 'metrics' ? 'show active' : '')"
        id="metrics"
        role="tabpanel"
      >
        <div *ngIf="!!isLoadingMetrics" awacSpinner></div>
        <srvy-metrics
          [metrics]="metrics"
          [filterOptions]="filterOptions"
          [selected]="selectedOptions"
          (selectedOptionsEvent)="getFilteredMetrics($event)"
        ></srvy-metrics>
      </div>
      <div
        *ngIf="!!allowHrMetrics"
        [class]="
          'tab-pane fade ' + (active === 'hr_metrics' ? 'show active' : '')
        "
        id="hr_metrics"
        role="tabpanel"
      >
        <div *ngIf="!!isLoadingHrMetrics" awacSpinner></div>
        <srvy-metrics
          [metrics]="hrMetrics"
          [filterOptions]="hr_filterOptions"
          [selected]="hr_selectedOptions"
          (selectedOptionsEvent)="getHrFilteredMetrics($event)"
        ></srvy-metrics>
      </div>
      <div
        *ngIf="!!allowHrAdministration"
        [class]="'tab-pane fade ' + (active === 'admin' ? 'show active' : '')"
        id="admin"
        role="tabpanel"
      >
        <srvy-administration></srvy-administration>
      </div>
    </div>
  </ng-template>
</ng-container>
