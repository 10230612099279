import { FilterOptions, SelectedOptions, Hierarchy } from "../models";

export function assignOptionsAndDefaults(
  options: FilterOptions,
  currentPeriod: boolean,
  defaultManager: boolean
): SelectedOptions {
  const selected: SelectedOptions = new SelectedOptions();

  // Default Manager
  if (!!defaultManager) {
    selected.manager =
      !!options.managers && !!options.managers[0]
        ? options.managers[0].networkId
        : "";
  }

  // Sort options
  options.templates = !!options.templates ? options.templates.sort() : [];
  options.years = !!options.years ? options.years.sort() : [];
  options.managers = !!options.managers
    ? options.managers.sort((a, b) => sortByName(a, b))
    : [];
  options.offices = !!options.offices ? options.offices.sort() : [];
  options.departments = !!options.departments ? options.departments.sort() : [];
  if (!!options.managers) {
    for (const manager of options.managers) {
      manager.directReports = !!manager.directReports
        ? manager.directReports.sort((a, b) => sortByName(a, b))
        : null;
    }
  }

  // Default values
  if (!!options.templates) {
    selected.template = options.templates.reverse()[0];
  }

  if (!!currentPeriod) {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    selected.period =
      currentMonth >= 2 && currentMonth < 5
        ? "Q1"
        : currentMonth >= 5 && currentMonth < 8
        ? "Q2"
        : currentMonth >= 8 && currentMonth < 11
        ? "Q3"
        : "Q4";
    if (!!options.years) {
      selected.year = options.years.reverse()[0];
    }
  }

  return selected;
}

export function sortByName(a: Hierarchy, b: Hierarchy): number {
  return a.employeeName > b.employeeName
    ? 1
    : a.employeeName === b.employeeName
    ? 0
    : -1;
}
