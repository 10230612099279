import { Component, OnInit } from "@angular/core";
import { DialogComponent } from "src/app/shared/modals/ng2-bootstrap-modal";
import { SurveyService } from "../../../survey/common";
import {
  Question,
  Answer,
  SurveyEntry,
  FullSurvey,
} from "../../../survey/common/models";
import { ExtendSurvey } from "../../common/models";
import { DashboardService } from "../../common/services/dashboard.service";
import {
  showAlternativeForQuestion,
  showAlternativeForConditional,
  showConditionalQuestion,
} from "../../../survey/common/helpers";
import { ToastrService } from "ngx-toastr";

export interface SurveyItem {
  surveyItem: ExtendSurvey;
}
@Component({
  selector: "srvy-reopen-modal",
  templateUrl: "./reopen-modal.component.html",
  styleUrls: ["./reopen-modal.component.css"],
})
export class ReopenModalComponent
  extends DialogComponent<SurveyItem, boolean>
  implements OnInit
{
  public survey: SurveyEntry = new SurveyEntry();
  public surveyItem: ExtendSurvey;
  public fullSurvey: FullSurvey = new FullSurvey();
  public isLoading: boolean = true;
  public serverError: boolean = false;
  public invalidUser: boolean = false;
  public currentStep: number;
  public currentQuestion: Question = new Question();
  public currentAnswer: Answer = new Answer();
  public lastCompletedQuestion: number = 0;

  constructor(
    private surveyService: SurveyService,
    private dashboardService: DashboardService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.getFullSurvey();
  }
  getFullSurvey() {
    this.surveyService
      .getFullSurvey(this.surveyItem.id, this.surveyItem.assignedTo)
      .subscribe(
        (fullSurveyResult: FullSurvey) => {
          this.isLoading = false;
          this.parsFullSurveyData(fullSurveyResult);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 400) {
            this.invalidUser = true;
          }
          this.serverError = true;
        }
      );
  }
  /**
   * @description Combine questions and answers to object to show in UI
   * @param fullSurveyResult <Completed Survey data from API>
   */
  parsFullSurveyData(fullSurveyResult: FullSurvey): void {
    this.fullSurvey = fullSurveyResult;
    const answers =
      fullSurveyResult.previousAnswers &&
      fullSurveyResult.previousAnswers.length
        ? fullSurveyResult.previousAnswers
        : [];
    this.survey.answers = [];
    for (const question of this.fullSurvey.questions) {
      const answer = answers.find((a) => a.questionId === question.id);
      this.survey.answers.push({
        user: this.surveyItem.assignedTo,
        questionId: answer.questionId,
        surveyId: this.surveyItem.id,
        questionAnswer: answer ? answer.questionAnswer : null,
        conditionalAnswer: answer ? answer.conditionalAnswer : null,
        alternativeAnswer: answer ? answer.alternativeAnswer : null,
      });
      if (answer && answer.questionAnswer) {
        this.lastCompletedQuestion++;
      }
    }
    this.setCurrentStep(0);
  }
  setCurrentStep(step: number): void {
    if (step >= 0) {
      this.currentStep = step;
      this.currentAnswer = this.survey.answers[this.currentStep];
      this.currentQuestion = this.fullSurvey.questions[this.currentStep];
      this.addCompletedQuestion(step);
    }
  }

  selectCurrentStep(step: number): void {
    if (this.questionCompleted(step)) {
      this.setCurrentStep(step);
    }
  }

  submitSurvey(): void {
    this.saveSurvey();
  }
  saveSurvey(): void {
    this.isLoading = true;
    this.dashboardService
      .updateSurvey(this.surveyItem.id, this.survey)
      .subscribe(
        (submitResult: boolean) => {
          this.isLoading = false;
          if (!!submitResult) {
            this.close();
            this.toastrService.success(
              "Survey updated successfully",
              "Success!"
            );
          } else {
            this.toastrService.error("Something went wrong", "Error!!");
          }
        },
        (error) => {
          this.isLoading = false;
          this.toastrService.error("Something went wrong", "Error!!");
        }
      );
  }

  showConditionalQuestion(): boolean {
    return showConditionalQuestion(this.currentQuestion, this.currentAnswer);
  }

  showAlternativeQuestion(): boolean {
    const showFlg =
      showAlternativeForQuestion(this.currentQuestion, this.currentAnswer) ||
      showAlternativeForConditional(this.currentQuestion, this.currentAnswer);
    return showFlg;
  }

  isNextDisabled(): boolean {
    const currentDisabled =
      this.currentQuestion.required && !this.currentAnswer.questionAnswer;
    const conditionalDisabled =
      showConditionalQuestion(this.currentQuestion, this.currentAnswer) &&
      this.currentQuestion.conditionalQuestion.required &&
      !this.currentAnswer.conditionalAnswer;
    const alternativeDisabled =
      showAlternativeForQuestion(this.currentQuestion, this.currentAnswer) &&
      this.currentQuestion.alternativeQuestion.required &&
      !this.currentAnswer.alternativeAnswer;
    const alternativeConditionalDisabled =
      showAlternativeForConditional(this.currentQuestion, this.currentAnswer) &&
      this.currentQuestion.conditionalQuestion.alternativeQuestion.required &&
      !this.currentAnswer.alternativeAnswer;

    return (
      currentDisabled ||
      conditionalDisabled ||
      alternativeDisabled ||
      alternativeConditionalDisabled
    );
  }

  goBack(): void {
    this.close();
  }
  questionCompleted(step: number): boolean {
    return this.lastCompletedQuestion >= step;
  }

  isLastCompletedQuestion(step: number): boolean {
    return this.lastCompletedQuestion === step;
  }

  addCompletedQuestion(currentStep: number): void {
    if (!this.questionCompleted(currentStep)) {
      this.lastCompletedQuestion = currentStep;
    }
  }
}
