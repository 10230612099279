import { Component, OnInit } from "@angular/core";

@Component({
  selector: "srvy-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
})
export class LayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
