<div *ngIf="!!isLoading" awacSpinner></div>

<div class="modal-dialog modal-md">
  <div class="modal-content">
    <p
      *ngIf="serverError"
      class="no_information_message cursor"
      (click)="getManagerList()"
    >
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      There was an error while trying to retrieve current survey. Please try
      again later.
    </p>
    <form *ngIf="!serverError" #delegationForm="ngForm">
      <div class="modal-header">
        <h3 class="modal-title" id="modal-title">Add Delegation</h3>
        <button type="button" class="close" (click)="close()">&times;</button>
      </div>
      <div class="modal-body" id="modal-body">
        <div *ngIf="!!serverError; then show_error; else show_edit"></div>
        <ng-template #show_error>
          <p class="no_information_message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> There
            was an error while trying to retrieve document types. Please try
            again later.
          </p>
        </ng-template>
        <ng-template #show_edit>
          <fieldset>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">Original Manager</label>
                  <div class="controls">
                    <ng-select
                      required
                      [items]="managerList"
                      name="originalManager"
                      bindLabel="name"
                      bindValue="networkId"
                      (change)="getReportees(); managerChanged()"
                      [(ngModel)]="originalManager"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label"
                    >New Manager (Delegated Manager who is responsible for
                    surveys)
                  </label>
                  <div class="controls">
                    <ng-select
                      required
                      [items]="delegatedList"
                      name="delegatedManager"
                      bindLabel="name"
                      bindValue="networkId"
                      [(ngModel)]="delegatedManager"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label" for="documentDate"
                    >Delegation Start Date</label
                  >
                  <div class="controls">
                    <div class="input-group">
                      <input
                        angular-mydatepicker
                        name="date"
                        required
                        [options]="datePickerOptions"
                        [(ngModel)]="startDate"
                        (click)="dp.toggleCalendar()"
                        #dp="angular-mydatepicker"
                        placeholder="Click to select a date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label" for="documentDate"
                    >Duration</label
                  >
                  <div class="controls">
                    <div class="input-group">
                      <select
                        class="form-control"
                        required
                        id="duration"
                        name="duration"
                        [(ngModel)]="duration"
                      >
                        <option
                          *ngFor="let duration of durationList"
                          [ngValue]="duration.value"
                        >
                          {{ duration.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6" *ngIf="duration === '0'">
                <div class="form-group">
                  <label class="control-label" for="documentDate"
                    >Delegation End Date</label
                  >
                  <div class="controls">
                    <div class="input-group">
                      <input
                        angular-mydatepicker
                        name="dateend"
                        required
                        [options]="datePickerOptions"
                        [(ngModel)]="endDate"
                        (click)="dp.toggleCalendar()"
                        #dp="angular-mydatepicker"
                        placeholder="Click to select a date"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label">{{
                    reportees
                      ? "Select Employee(s) to be delegated"
                      : "*Please select Original manager to view the employee list"
                  }}</label>
                  <div class="tableCont" *ngIf="reportees">
                    <table class="table scroll mar-b-0">
                      <thead>
                        <tr>
                          <th style="width: 15%">
                            <div class="custom-control custom-checkbox">
                              <input
                                class="custom-control-input"
                                id="all"
                                [disabled]="!reportees.directReports.length"
                                name="first"
                                [(ngModel)]="selectAll"
                                type="checkbox"
                                (change)="selectAllReportees()"
                              />
                              <label
                                class="custom-control-label"
                                for="all"
                              ></label>
                            </div>
                          </th>
                          <th style="width: 50%">
                            <a class="sort_caret curser-default"> Name </a>
                          </th>
                          <th style="width: 35%">
                            <a class="sort_caret curser-default"> Office </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let reportee of reportees.directReports;
                            let i = index
                          "
                        >
                          <td style="width: 15%">
                            <div class="custom-control custom-checkbox">
                              <input
                                class="custom-control-input"
                                id="reportee{{ i }}"
                                name="reportee{{ i }}"
                                [(ngModel)]="reportee.check"
                                type="checkbox"
                                (change)="reporteeSelectionChanged(reportee)"
                              />
                              <label
                                class="custom-control-label"
                                for="reportee{{ i }}"
                              ></label>
                            </div>
                          </td>
                          <td style="width: 50%">{{ reportee.name }}</td>
                          <td style="width: 35%">{{ reportee.office }}</td>
                        </tr>
                        <tr *ngIf="!reportees.directReports.length">
                          <td></td>
                          <td colspan="2">
                            No direct reports under
                            {{ getEmployeeName(originalManager) }}
                          </td>
                        </tr>
                        <tr
                          class="tr-head"
                          *ngIf="
                            reportees.delegatedReports &&
                            reportees.delegatedReports.length
                          "
                        >
                          <td colspan="3">
                            Employee(s) delegated to
                            {{ getEmployeeName(originalManager) }}
                          </td>
                        </tr>
                        <tr
                          *ngFor="let reportee of reportees.delegatedReports"
                          title="{{ getDelegationPeriodText(reportee.period) }}"
                        >
                          <td style="width: 15%"></td>
                          <td style="width: 50%">
                            {{ reportee.employee.name }}
                          </td>
                          <td style="width: 35%">
                            {{ reportee.employee.office }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="control-label" for="documentDescription"
                    >Comment</label
                  >
                  <div class="controls">
                    <textarea
                      type="text"
                      id="documentDescription"
                      class="form-control"
                      name="desc"
                      [(ngModel)]="comments"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </ng-template>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-success"
          type="submit"
          [disabled]="
            !!serverError || delegationForm.invalid || !selectedReportees.length
          "
          (click)="update()"
        >
          <span class="fa fa-check"></span> Save & Close
        </button>
        <button class="btn btn-danger" type="button" (click)="close()">
          <span class="fa fa-ban"></span> Cancel
        </button>
      </div>
    </form>
  </div>
</div>
