<div *ngIf="isLoading; then service_call; else service_end"></div>
<ng-template #service_call>
  <div *ngIf="!serverError; then show_spinner; else show_error"></div>
  <ng-template #show_spinner>
    <div awacSpinner></div>
  </ng-template>
  <ng-template #show_error>
    <p class="no_information_message">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      {{
        !invalidUser
          ? "There was an error while trying to retrieve current survey. Please try again later."
          : "You are not authorized to view this survey due to invalid hierarchy level"
      }}
    </p>
  </ng-template>
</ng-template>
<ng-template #service_end>
  <div
    *ngIf="
      !fullSurvey.questions || fullSurvey.questions.length === 0;
      then no_questions;
      else show_questions
    "
  ></div>
  <ng-template #no_questions>
    <p class="no_information_message">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      There are no questions available for current survey.
    </p>
  </ng-template>
  <ng-template #show_questions>
    <div class="container">
      <div *ngIf="!showOnly; then show_wizard; else show_summary"></div>
      <ng-template #show_wizard>
        <div class="row">
          <div class="col-md-8">
            <h2>{{ fullSurvey.title }}</h2>
          </div>
          <div class="col-md-4">
            <button
              class="btn btn-light pull-right text-right"
              (click)="saveSurvey()"
            >
              <i class="fa fa-save" aria-hidden="true"></i>
              Save for Later
            </button>
          </div>
          <div class="title_divider"></div>
        </div>
        <div id="wizard-progress">
          <ol class="step-indicator">
            <li
              *ngFor="let step of fullSurvey.questions; let i = index"
              [class]="
                !isLastCompletedQuestion(i) && currentStep === i
                  ? 'active-completed'
                  : currentStep === i
                  ? 'active'
                  : isLastCompletedQuestion(i)
                  ? 'last'
                  : questionCompleted(i)
                  ? 'complete'
                  : ''
              "
            >
              <div class="step" (click)="selectCurrentStep(i)">
                <i
                  *ngIf="questionCompleted(i) && !isLastCompletedQuestion(i)"
                  _ngcontent-c4=""
                  aria-hidden="true"
                  class="fa fa-check"
                ></i>
              </div>
              <div class="caption hidden-xs hidden-sm">{{ i + 1 }}</div>
            </li>
            <li
              [class]="
                currentStep === fullSurvey.questions.length ? 'active' : ''
              "
            >
              <div class="step"></div>
              <div class="caption hidden-xs hidden-sm">Summary</div>
            </li>
          </ol>
        </div>
        <div
          *ngIf="
            currentStep < fullSurvey.questions.length;
            then show_question;
            else show_summary
          "
        ></div>
        <ng-template #show_question>
          <srvy-question
            [question]="currentQuestion"
            [answer]="currentAnswer"
          ></srvy-question>
        </ng-template>
        <ng-template #show_summary>
          <srvy-summary
            [survey]="survey"
            [questions]="fullSurvey.questions"
          ></srvy-summary>
          <div class="summaryCheck clearfix">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkSummary1"
                [(ngModel)]="acknowledge"
              />
              <label class="custom-control-label" for="checkSummary1">{{
                fullSurvey.acknowledge
              }}</label>
            </div>
          </div>
        </ng-template>
        <div class="btn_options">
          <div *ngIf="currentStep > 0; then prevStep; else goBackWiz"></div>
          <ng-template #prevStep>
            <button
              class="btn btn-light text-left"
              (click)="setCurrentStep(currentStep - 1)"
            >
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              Back
            </button>
          </ng-template>
          <ng-template #goBackWiz>
            <button class="btn btn-light text-left" (click)="goBack()">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              Back To {{ !!fromSearch ? "Search" : "Dashboard" }}
            </button>
          </ng-template>
          <div
            *ngIf="
              currentStep >= fullSurvey.questions.length ||
                (showConditionalQuestion() &&
                  !!currentQuestion.conditionalEndsFlow);
              then show_finish;
              else show_next
            "
          ></div>
          <ng-template #show_next>
            <button
              class="btn btn-success pull-right text-right"
              (click)="setCurrentStep(currentStep + 1)"
              [disabled]="isNextDisabled()"
            >
              Next
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </ng-template>
          <ng-template #show_finish>
            <button
              class="btn btn-success pull-right text-right"
              (click)="submitSurvey()"
              [disabled]="
                (!acknowledge && currentStep >= fullSurvey.questions.length) ||
                (!!currentQuestion &&
                  ((!!currentQuestion.required &&
                    !currentAnswer.questionAnswer) ||
                    (showConditionalQuestion() &&
                      !!currentQuestion.conditionalQuestion.required &&
                      !currentAnswer.conditionalAnswer)))
              "
            >
              <i class="fa fa-save" aria-hidden="true"></i>
              Submit
            </button>
          </ng-template>
        </div>
      </ng-template>
      <ng-template #show_summary>
        <h2>
          {{ fullSurvey.title }} -
          <span>Completed Date - {{ submitDate | date: "M/d/yyyy" }}</span>
        </h2>
        <srvy-summary
          [survey]="survey"
          [questions]="fullSurvey.questions"
        ></srvy-summary>
        <div class="btn_options">
          <button class="btn btn-light text-left" (click)="goBack()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back To {{ !!fromSearch ? "Search" : "Dashboard" }}
          </button>
        </div>
      </ng-template>
    </div>
  </ng-template>
</ng-template>
