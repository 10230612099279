import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GlobalConstants } from "../global-constants";
import { ApiError } from "../model/api-error";
import { environment } from "../../../environments/environment";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const data = this.getErrorData(error, req);
        sessionStorage.setItem(
          GlobalConstants.API_ERROR_SESSIONSTORAGE_KEY,
          JSON.stringify(data)
        );
        this.router.navigate(["serverError"]);
        return throwError(error);
      })
    );
  }
  /**
   * @description get data for server error page
   * @param error <HttpErrorResponse>
   * @param request <HttpRequest> we need HttpRequest because in case of CORS error.url in HttpErrorResponse will be null
   */
  private getErrorData(
    error: HttpErrorResponse,
    request: HttpRequest<any>
  ): ApiError {
    let header: string;
    let body = `There was an error while connecting to the server. Please try again later.\n<i>Report the error with URL : ${request.url.replace(
      environment.apiUrl,
      "./"
    )}</i>`;
    let path = window.location.pathname;
    let status = error.status;
    if (error.status === 401) {
      path = "";
      body = `You are unauthorized to visit this application. Please contact your administrator.`;
    }
    if (window.navigator.onLine) {
      header = GlobalConstants.HTTP_STATUS_CONSTANTS[status];
    } else {
      status = null;
      header = `You are offline `;
      body = `Please check your internet connection and try again.`;
    }
    return {
      path: path,
      pathText: path.indexOf("dashboard") >= 0 ? "Go to Home" : "Go Back",
      status: status,
      header: header ? header : `Something went wrong`,
      body: body,
    };
  }
}
