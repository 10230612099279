<div class="modal-dialog modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-title">
        {{ stop ? "Stop delegation of " : "Employee(s) delegated to" }}
        {{ delegation.delegatedManager.name }}
      </h3>
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <div class="form-group row">
        <label class="col-sm-3">Original Manager </label>
        <p class="col-sm-9">{{ delegation.originalManager.name }}</p>
      </div>
      <div class="form-group row">
        <label class="col-sm-3">Period</label>
        <p class="col-sm-9">
          {{ delegation.startDate | date: "dd MMM  yyyy" }} -
          {{
            !delegation.endDate
              ? "Unspecified"
              : (delegation.endDate | date: "dd MMM yyyy")
          }}
        </p>
      </div>
      <div class="tableCont">
        <table class="table mar-b-0">
          <thead>
            <tr>
              <th></th>
              <th>Employee Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let employee of delegation.directReports; let i = index"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ employee.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer" *ngIf="stop">
      <button class="btn btn-warning" type="submit" (click)="stopClick()">
        <span class="fa fa-check"></span> Confirm
      </button>
    </div>
  </div>
</div>
