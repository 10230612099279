import { Component, OnInit } from "@angular/core";
import {
  SelectedOptions,
  Metrics,
  FilterOptions,
  EmployeeMetric,
} from "../common/models";
import { Router } from "@angular/router";

@Component({
  selector: "srvy-administration",
  templateUrl: "./administration.component.html",
  styleUrls: ["./administration.component.css"],
})
export class AdministrationComponent implements OnInit {
  public operation: string = "";

  constructor(private router: Router) {}

  ngOnInit() {}

  navigateToSearch() {
    this.router.navigate(["/search"]);
  }
}
