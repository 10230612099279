import { GlobalConstants } from "../global-constants";

export abstract class SessionStorageHandling {
  constructor() {}

  static allowInternetExplorerUsage(): void {
    sessionStorage.setItem(GlobalConstants.INTERNET_EXPLORER_LOCAL_STORAGE, "");
  }

  static isInternetExplorerUsageAllowed(): boolean {
    const item = sessionStorage.getItem(
      GlobalConstants.INTERNET_EXPLORER_LOCAL_STORAGE
    );
    return item != null && item !== undefined;
  }
}
