import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { OktaAuthService } from "@okta/okta-angular";
import { User } from "./models";
import { GlobalConstants } from "../shared/global-constants";
import { SessionStorageHandling } from "../shared/storage/session-storage-handling";

@Injectable()
export class AuthenticationService {
  private userData: BehaviorSubject<User> = new BehaviorSubject<User>(
    new User()
  );

  constructor(public oktaAuth: OktaAuthService) {}

  logout(): void {
    this.oktaAuth
      .logout(GlobalConstants.DEFAULT_PAGE)
      .then(() => this.oktaAuth.loginRedirect(GlobalConstants.DEFAULT_PAGE))
      .catch(() => {
        this.oktaAuth.loginRedirect(GlobalConstants.DEFAULT_PAGE);
      });
  }

  notifyUserDataChanges(): void {
    let objLocalStorage = null;
    if (!!localStorage["okta-token-storage"]) {
      objLocalStorage = JSON.parse(localStorage["okta-token-storage"]);
    }
    if (
      !!objLocalStorage &&
      !!objLocalStorage.idToken &&
      !!objLocalStorage.idToken.claims
    ) {
      const nextUser = this.mapUserData(objLocalStorage);
      this.userData.next(nextUser);
    } else {
      console.log("No user information was provided");
    }
  }

  getUserData(): Observable<User> {
    return this.userData.asObservable();
  }

  getToken(): string {
    const tokenObj = JSON.parse(localStorage.getItem("okta-token-storage"));
    let result = null;
    if (
      !!tokenObj &&
      !!tokenObj["idToken"] &&
      !!tokenObj["idToken"]["idToken"]
    ) {
      result = tokenObj["idToken"]["idToken"];
    }
    return result;
  }

  mapUserData(localStorageObj: any): User {
    const newUser = new User();
    newUser.displayName = localStorageObj.idToken.claims["dn"];
    newUser.networkId =
      localStorageObj.idToken.claims["preferred_username"].split("@")[0];
    return newUser;
  }

  setToken(tokenId: string): void {
    const token = JSON.stringify({ idToken: { idToken: tokenId } });
    localStorage.setItem("okta-token-storage", token);
  }

  shouldNavigateToIeErrorPage(currentUrl: string): boolean {
    return (
      !SessionStorageHandling.isInternetExplorerUsageAllowed() &&
      currentUrl.toLowerCase() !== GlobalConstants.UNSUPPORTED_BROWSER_URL &&
      this.isUserUsingInternetExplorer()
    );
  }

  isUserUsingInternetExplorer(): boolean {
    const userAgent = window.navigator.userAgent;

    const msie = userAgent.indexOf("MSIE ");
    if (msie > 0) {
      return true;
    }

    const trident = userAgent.indexOf("Trident/");
    if (trident > 0) {
      return true;
    }

    const edge = userAgent.indexOf("Edge/");
    if (edge > 0) {
      return true;
    }

    return false;
  }
}
