<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="d-flex flex-grow-1">
    <a class="navbar-brand d-none d-lg-inline-block">
      <img
        src="../../../../assets/images/awac_home_logo.png"
        (click)="navigateToHome()"
      />
    </a>
    <a class="navbar-brand-two mx-auto d-lg-none d-inline-block">
      <img
        src="../../../../assets/images/awac_home_logo.png"
        (click)="navigateToHome()"
      />
    </a>
    <div class="w-100 text-right">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#myNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
  <div class="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
    <ul class="navbar-nav ml-auto flex-nowrap">
      <div *ngIf="!serverError; then show_user; else show_error"></div>
      <ng-template #show_user>
        <li class="nav-item">
          <a class="nav-link m-2 menu-item nav-active"
            >Signed in as <span>{{ loggedUser }}</span></a
          >
        </li>
        <li>
          <a class="btn btn-default" (click)="logout()"
            ><span class="fa fa-fw fa-sign-out"></span> Log out</a
          >
        </li>
      </ng-template>
      <ng-template #show_error>
        <li>
          <a class="nav-link m-2 menu-item nav-active"
            ><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            Unable to retrieve user</a
          >
        </li>
      </ng-template>
    </ul>
  </div>
</nav>
