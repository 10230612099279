import { Component, OnInit } from "@angular/core";
import { DialogService } from "../../../shared/modals/ng2-bootstrap-modal";
import { ReopenModalComponent } from "../../modals/reopen-modal/reopen-modal.component";
import { DashboardService } from "../../common/services/dashboard.service";
import { ExtendSurvey } from "../../common/models";
import { GlobalConstants } from "src/app/shared/global-constants";

@Component({
  selector: "srvy-reopen-and-fix",
  templateUrl: "./reopen-and-fix.component.html",
  styleUrls: ["./reopen-and-fix.component.css"],
})
export class ReopenAndFixComponent implements OnInit {
  public completedSurveys: ExtendSurvey[] = [];
  public isLoading: boolean;
  public serverError: boolean;
  public searchText: string = "";
  public pageNumber: number;
  public sortProp: string = "targetName";
  public maxItemsPerPage: number;
  public sortReverse: boolean;
  public showReopenSurvey: boolean;
  constructor(
    private dialogService: DialogService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    const localStorageData = JSON.parse(
      localStorage.getItem(GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY)
    );
    this.maxItemsPerPage =
      localStorageData && localStorageData.maxItemsPerPage
        ? localStorageData.maxItemsPerPage
        : 10;
  }
  /**
   * @description Fetch list of Completed surveys
   */
  getCompletedSurveys() {
    this.completedSurveys = [];
    this.isLoading = true;
    this.dashboardService
      .getCompletedSurveys(this.searchText)
      .subscribe((data: ExtendSurvey[]) => {
        this.completedSurveys = data;
        this.isLoading = false;
      });
    this.pageNumber = 1;
  }
  /**
   * @description set sort property to the given name  and set sortReverse
   * @param name <string>
   */
  sortType(name: string): void {
    this.sortReverse = this.sortProp === name ? !this.sortReverse : false;
    this.sortProp = name;
  }
  /**
   * @description Set maxItemsPerPage property to the given value
   * @param itemsPerPage <Number>
   */
  setPaging(itemsPerPage: number): void {
    this.maxItemsPerPage = itemsPerPage;
    this.setDefault();
  }
  /**
   * @description This method id to set default filtering option in the page in localstorage
   */
  setDefault() {
    let localStorageData = JSON.parse(
      localStorage.getItem(GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY)
    );
    if (!localStorageData) {
      // take copy of local storage if it is already there else create one
      localStorageData = {};
    }
    localStorageData.maxItemsPerPage = this.maxItemsPerPage;
    localStorage.setItem(
      GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY,
      JSON.stringify(localStorageData)
    );
  }
  /**
   * @description This method will set page to it's initial state. Use full when page goes into error state
   */
  resetPage() {
    this.serverError = false;
    this.showReopenSurvey = false;
    this.searchText = "";
  }
  reopen(item: ExtendSurvey) {
    this.dialogService
      .addDialog(
        ReopenModalComponent,
        {
          surveyItem: item,
        },
        {}
      )
      .subscribe();
  }
}
