import { Component, Type, ComponentFactoryResolver } from "@angular/core";
import { DialogComponent } from "./dialog.component";
import { DialogWrapperComponent } from "./dialog-wrapper.component";
import { Observable } from "rxjs";
import { DialogOptions } from "./dialog.service";
import { DialogBaseComponent } from "./dialog-base.component";

@Component({
  selector: "srvy-dialog-holder",
  template: "<ng-template #element></ng-template>",
})
export class DialogHolderComponent extends DialogBaseComponent {
  /**
   * Array of dialogs
   * @param <Array<DialogComponent> >
   */
  dialogs: Array<DialogComponent<any, any>> = [];

  /**
   * Constructor
   * @param <ComponentFactoryResolver} resolver
   */
  constructor(private resolver: ComponentFactoryResolver) {
    super();
  }

  /**
   * Adds dialog
   * @param <Type<DialogComponent>> component
   * @param <object?> data
   * @param <DialogOptions?> options
   * @return <Observable<*>>
   */
  addDialog<T, T1>(
    component: Type<DialogComponent<T, T1>>,
    data?: T,
    options?: DialogOptions
  ): Observable<T1> {
    options = options || <DialogOptions>{};
    const factory = this.resolver.resolveComponentFactory(
      DialogWrapperComponent
    );
    const componentRef = this.element.createComponent(factory, options.index);
    const dialogWrapper: DialogWrapperComponent = <DialogWrapperComponent>(
      componentRef.instance
    );
    const _component: DialogComponent<T, T1> =
      dialogWrapper.addComponent(component);
    _component.remove.subscribe((c) => this.removeDialog(c));
    if (typeof options.index !== "undefined") {
      this.dialogs.splice(options.index, 0, _component);
    } else {
      this.dialogs.push(_component);
    }
    setTimeout(() => {
      dialogWrapper.container.nativeElement.classList.add("show");
      dialogWrapper.container.nativeElement.classList.add("in");
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("modal-open");
    });
    if (options.autoCloseTimeout) {
      setTimeout(() => {
        this.removeDialog(_component);
      }, options.autoCloseTimeout);
    }
    if (options.closeByClickingOutside) {
      dialogWrapper.closeByClickOutside();
    }
    if (options.backdropColor) {
      dialogWrapper.container.nativeElement.style.backgroundColor =
        options.backdropColor;
    }
    return _component.fillData(data);
  }

  /**
   * Removes dialog
   * @param <DialogComponent> component
   */
  removeDialog(component: DialogComponent<any, any>) {
    const element = component.wrapper.container.nativeElement;

    element.classList.remove("show");
    element.classList.remove("in");
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-open");
    setTimeout(() => {
      this._removeElement(component);
    }, 300);
  }

  private _removeElement(component) {
    const index = this.dialogs.indexOf(component);
    if (index > -1) {
      this.element.remove(index);
      this.dialogs.splice(index, 1);
    }
  }

  clear() {
    this.element.clear();
    this.dialogs = [];
  }
}
