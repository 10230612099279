import { Component, OnInit } from "@angular/core";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { DialogComponent } from "src/app/shared/modals/ng2-bootstrap-modal";
interface ExtendSurveyCount {
  surveyCount: number;
}
@Component({
  selector: "srvy-duedate-modal",
  templateUrl: "./duedate-modal.component.html",
  styleUrls: ["./duedate-modal.component.css"],
})
export class DuedateModalComponent
  extends DialogComponent<ExtendSurveyCount, { date: Date; comment: string }>
  implements OnInit
{
  public surveyCount: number;
  public datePickerOptions: IAngularMyDpOptions;
  public date: IMyDateModel;
  public description: string;
  constructor() {
    super();
  }
  ngOnInit() {
    if (!this.surveyCount) {
      this.close();
      return;
    }
    const today = new Date();
    this.datePickerOptions = {
      dateFormat: "mmm-dd-yyyy",
      selectorHeight: "36px",
      disableUntil: {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      },
    };
  }
  save() {
    if (this.date) {
      this.result = {
        date: new Date(
          this.date.singleDate.date.year,
          this.date.singleDate.date.month - 1,
          this.date.singleDate.date.day
        ),
        comment: this.description,
      };
    }
    this.close();
  }
}
