import { Component, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  selector: "srvy-dialog-base",
  template: "<ng-template #element></ng-template>",
})
export class DialogBaseComponent {
  /**
   * Target element to insert dialog content component
   */
  @ViewChild("element", { read: ViewContainerRef, static: true })
  public element: ViewContainerRef;
}
