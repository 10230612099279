import { Component, OnInit, Input } from "@angular/core";
import { Question, SurveyEntry } from "../common/models";

@Component({
  selector: "srvy-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.css"],
})
export class SummaryComponent implements OnInit {
  @Input() questions: Question[] = [];
  @Input() survey: SurveyEntry = new SurveyEntry();

  constructor() {}

  ngOnInit() {}
}
