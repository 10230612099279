import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { OktaAuthGuard } from "@okta/okta-angular";
import { GlobalConstants } from "../shared/global-constants";

@Injectable()
export class AuthenticationGuardService implements CanActivate {
  constructor(
    private oktaAuthGuard: OktaAuthGuard,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authenticationService.shouldNavigateToIeErrorPage(state.url)) {
      this.router.navigate([GlobalConstants.UNSUPPORTED_BROWSER_URL]);
    } else if (
      state.url.toLowerCase() === GlobalConstants.UNSUPPORTED_BROWSER_URL &&
      !this.authenticationService.isUserUsingInternetExplorer()
    ) {
      this.router.navigate([GlobalConstants.DEFAULT_PAGE]);
    }

    return this.oktaAuthGuard
      .canActivate(route, state)
      .then((isAuthenticated: boolean) => {
        this.authenticationService.notifyUserDataChanges();
        return isAuthenticated;
      });
  }
}
