import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalConstants } from "../global-constants";
import { ApiError } from "../model/api-error";

@Component({
  selector: "srvy-api-error-page",
  templateUrl: "./api-error-page.component.html",
  styleUrls: ["./api-error-page.component.css"],
})
export class ApiErrorPageComponent implements OnInit {
  public errorData: ApiError;
  constructor(private router: Router) {
    this.errorData = JSON.parse(
      sessionStorage.getItem(GlobalConstants.API_ERROR_SESSIONSTORAGE_KEY)
    );
    sessionStorage.removeItem(GlobalConstants.API_ERROR_SESSIONSTORAGE_KEY);
  }
  ngOnInit() {
    if (!this.errorData) {
      this.router.navigate(["dashboard"]);
    }
  }
}
