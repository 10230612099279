export class Survey {
  public id: string;
  public questionSetId: string;
  public assignedTo: string;
  public assignedToName: string;
  public startDate: Date;
  public endDate: Date;
  public completedDate: Date;
  public title: string;
  public acknowledge: string;
}
