import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  NotFoundErrorPageComponent,
  SpinnerDirective,
  FilterPipe,
} from ".";
import {
  AlertComponent,
  ConfirmComponent,
  PromptComponent,
  AppAlertsService,
} from "./modals";
import { DialogService } from "./modals/ng2-bootstrap-modal";
import { ApiErrorPageComponent } from "./api-error-page/api-error-page.component";
import { HighlightPipe } from "./directives/highlight-pipe";

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundErrorPageComponent,
    SpinnerDirective,
    AlertComponent,
    ConfirmComponent,
    PromptComponent,
    FilterPipe,
    ApiErrorPageComponent,
    HighlightPipe,
  ],
  imports: [RouterModule, BrowserModule, CommonModule, FormsModule],
  entryComponents: [AlertComponent, ConfirmComponent, PromptComponent],
  exports: [SpinnerDirective, FilterPipe, HighlightPipe],
  providers: [AppAlertsService, DialogService],
})
export class SharedModule {}
