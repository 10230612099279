<div class="modal-dialog modal-md">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-title">
        Update Due Date for <b>{{ surveyCount }}</b> survey(s)
      </h3>
      <button type="button" class="close" (click)="close()">&times;</button>
    </div>
    <div class="modal-body" id="modal-body">
      <form>
        <fieldset>
          <div class="row">
            <div class="col-sm-12">
              <p class="info">
                *Please select the new due date for extending the survey period.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label" for="documentDate"
                  >Extended Due Date</label
                >
                <div class="controls">
                  <div class="input-group">
                    <input
                      angular-mydatepicker
                      name="date"
                      [options]="datePickerOptions"
                      [(ngModel)]="date"
                      (click)="dp.toggleCalendar()"
                      #dp="angular-mydatepicker"
                      placeholder="Click to select a date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="form-group">
                <label class="control-label" for="documentDescription"
                  >Comment</label
                >
                <div class="controls">
                  <textarea
                    type="text"
                    id="documentDescription"
                    class="form-control"
                    name="desc"
                    [(ngModel)]="description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-success"
        type="button"
        [disabled]="!date"
        (click)="save()"
      >
        <span class="fa fa-check"></span> Update & Close
      </button>
      <button class="btn btn-danger" type="button" (click)="close()">
        <span class="fa fa-ban"></span> Cancel
      </button>
    </div>
  </div>
</div>
