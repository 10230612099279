import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: Array<any>, filterValue: any): Array<any> {
    if (!filterValue) {
      return items;
    }
    if (typeof filterValue === "string") {
      return items.filter((item) => {
        return Object.keys(item).find((key) => {
          return (
            item[key] &&
            JSON.stringify(item[key])
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) >= 0
          );
        });
      });
    }
    return items.filter((item) => {
      return Object.keys(filterValue).find((key) => {
        return (
          !filterValue[key] ||
          (item[key] &&
            item[key].toLowerCase().indexOf(filterValue[key].toLowerCase()) >=
              0)
        );
      });
    });
  }
}
