<div *ngIf="!!isLoading || !!isLoadingSearch" awacSpinner></div>
<div *ngIf="!isLoading" class="container">
  <div class="row">
    <div class="col">
      <button class="btn btn-primary pageBackBtn" (click)="backToHome()">
        <i class="fa fa-arrow-left"></i> Back
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h2>Search completed surveys</h2>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="greyBkg">
        <form id="searchSurveysForm">
          <div *ngIf="!!managerAccess || !!hrAccess" class="row">
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="metricsFormSurveyType">Survey type</label>
                <select
                  class="form-control"
                  id="metricsFormSurveyType"
                  name="metricsFormSurveyType"
                  [(ngModel)]="selectedOptions.template"
                >
                  <option
                    *ngFor="let temp of filterOptions.templates"
                    [ngValue]="temp"
                  >
                    {{ temp }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="metricsFormManager">Manager</label>
                <select
                  class="form-control"
                  id="metricsFormManager"
                  name="metricsFormManager"
                  (ngModelChange)="onManagerChange($event)"
                  [(ngModel)]="selectedManager"
                  [disabled]="myConnectSelected()"
                >
                  <option [ngValue]="undefined">Any Manager</option>
                  <option
                    *ngFor="let manager of filterOptions.managers"
                    [ngValue]="manager"
                  >
                    {{ manager.employeeName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="searchSurveysEmployee">Employee</label>
                <select
                  class="form-control"
                  id="searchSurveysEmployee"
                  name="searchSurveysEmployee"
                  [(ngModel)]="selectedOptions.employee"
                  [disabled]="
                    myConnectSelected() ||
                    !selectedManager ||
                    !selectedManager.directReports ||
                    selectedManager.directReports.length === 0
                  "
                >
                  <option [ngValue]="undefined">Any Employee</option>
                  <ng-container *ngIf="!!selectedManager">
                    <option
                      *ngFor="let employee of selectedManager.directReports"
                      [ngValue]="employee.employeeName"
                    >
                      {{ employee.employeeName }}
                    </option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="metricsFormOffice">Office</label>
                <select
                  class="form-control"
                  id="metricsFormOffice"
                  name="metricsFormOffice"
                  [(ngModel)]="selectedOptions.office"
                  [disabled]="myConnectSelected()"
                >
                  <option [ngValue]="undefined">Any Office</option>
                  <option
                    *ngFor="let office of filterOptions.offices"
                    [ngValue]="office"
                  >
                    {{ office }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div *ngIf="!!managerAccess || !!hrAccess" class="col-12 col-md">
              <label for="metricsFormLocation">Department</label>
              <select
                class="form-control"
                id="metricsFormLocation"
                name="metricsFormLocation"
                [(ngModel)]="selectedOptions.department"
                [disabled]="myConnectSelected()"
              >
                <option [ngValue]="undefined">Any Department</option>
                <option
                  *ngFor="let department of filterOptions.departments"
                  [ngValue]="department"
                >
                  {{ department }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="metricsFormPeriod">Period</label>
                <select
                  class="form-control"
                  id="metricsFormPeriod"
                  name="metricsFormPeriod"
                  [(ngModel)]="selectedOptions.period"
                >
                  <option [ngValue]="undefined">Any Period</option>
                  <option
                    *ngFor="let period of filterOptions.periods"
                    [ngValue]="period"
                  >
                    {{ period }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md">
              <div class="form-group">
                <label for="metricsFormYear">Year</label>
                <select
                  class="form-control"
                  id="metricsFormYear"
                  name="metricsFormYear"
                  [(ngModel)]="selectedOptions.year"
                >
                  <option [ngValue]="undefined">Any Year</option>
                  <option
                    *ngFor="let year of filterOptions.years"
                    [ngValue]="year"
                  >
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-auto d-flex align-items-end">
              <button
                type="submit"
                class="btn btn-primary btn-lg btnRed mt-2 mb-2"
                (click)="searchSurveys()"
              >
                <span class="fa fa-search"></span>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row managerOnly">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-hover fullTable">
          <thead>
            <tr>
              <th>Survey</th>
              <th *ngIf="!!managerAccess || !!hrAccess">Completed By</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let survey of surveys">
              <td>{{ survey.title }}</td>
              <td *ngIf="!!managerAccess || !!hrAccess">
                {{ survey.assignedToName }}
              </td>
              <td>Completed {{ survey.submitDate | date: "M/d/yyyy" }}</td>
              <td>
                <button
                  class="btn btn-primary align-self-center"
                  (click)="
                    navigateToSurvey(survey.id, survey.assignedTo || loggedUser)
                  "
                >
                  View <span class="fa fa-eye"></span>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">{{ !!surveys ? surveys.length : 0 }} results</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
