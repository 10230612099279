import { Routes } from "@angular/router";
import { SurveyComponent } from "../survey";
import { AuthenticationGuardService } from "../auth";
import { DashboardComponent, SurveySearchComponent } from "../dashboard";
import {
  OktaLoginRedirectComponent,
  OktaCallbackComponent,
} from "@okta/okta-angular";

export const LAYOUT_CHILD_ROUTES: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthenticationGuardService],
  },
  {
    path: "search",
    component: SurveySearchComponent,
    canActivate: [AuthenticationGuardService],
  },
  {
    path: "survey/:surveyId/:user",
    component: SurveyComponent,
    canActivate: [AuthenticationGuardService],
  },
  {
    path: "survey/:surveyId/:user/:fromSearch",
    component: SurveyComponent,
    canActivate: [AuthenticationGuardService],
  },
  { path: "implicit/callback", component: OktaCallbackComponent },
  { path: "login", component: OktaLoginRedirectComponent },
];
