import { Component, OnInit } from "@angular/core";
import { DialogComponent } from "src/app/shared/modals/ng2-bootstrap-modal";
import { Delegation } from "../../common/models";
interface EmployeeListWindow {
  delegation: Delegation;
  stop: boolean;
}
@Component({
  selector: "srvy-employee-list",
  templateUrl: "./employee-list.component.html",
  styleUrls: ["./employee-list.component.css"],
})
export class EmployeeListComponent
  extends DialogComponent<EmployeeListWindow, boolean>
  implements OnInit
{
  public delegation: Delegation;
  public stop: boolean;
  constructor() {
    super();
  }

  ngOnInit() {
    this.result = false;
  }
  stopClick() {
    this.result = true;
    this.close();
  }
}
