import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import {
  SurveyHeaders,
  FilterOptions,
  Metrics,
  SelectedOptions,
  ExtendableSurvey,
  DueDateExtensions,
  ExtendSurvey,
  Delegation,
  Employee,
  Reportee,
  AddDelegation,
} from "../models";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { UserRole } from "../../../auth/models";
import { FullSurvey, SurveyEntry } from "../../../survey/common/models";

@Injectable()
export class DashboardService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json" });
  }

  getUserRole(user: string): Observable<UserRole> {
    return this.get(`role/${user}`);
  }

  getSurveys(user: string): Observable<SurveyHeaders> {
    return this.get(user);
  }

  getFilterOptions(user: string): Observable<FilterOptions> {
    return this.get("filters/" + user);
  }

  getHrFilterOptions(): Observable<FilterOptions> {
    return this.http.get<FilterOptions>(environment.apiUrl + "Survey/filters");
  }

  getMetrics(
    selectedOptions: SelectedOptions,
    user: string
  ): Observable<Metrics> {
    return this.http.get<Metrics>(
      environment.apiUrl +
        "Survey/metrics/" +
        selectedOptions.template +
        "/" +
        selectedOptions.period +
        "/" +
        selectedOptions.year +
        "/" +
        user
    );
  }

  getHrMetrics(selectedOptions: SelectedOptions): Observable<Metrics> {
    return this.http.get<Metrics>(
      environment.apiUrl +
        "Survey/metrics/" +
        selectedOptions.template +
        "/" +
        selectedOptions.period +
        "/" +
        selectedOptions.year +
        (!!selectedOptions.manager ? "/" + selectedOptions.manager : "")
    );
  }

  searchSurveys(
    selectedOptions: SelectedOptions,
    user: string
  ): Observable<FullSurvey[]> {
    const body = JSON.stringify(selectedOptions);
    return this.http.post<FullSurvey[]>(
      environment.apiUrl + "Survey/search/" + user,
      body,
      { headers: this.headers }
    );
  }
  /**
   * @description Server call to fetch list of surveys of which due date is past date from current quarter and previous quarter
   * @author &jo
   * @param office <string>
   * @param surveyType  <number>
   */
  getExtendableSurveys(
    office: string,
    surveyType: number
  ): Observable<ExtendableSurvey[]> {
    return this.http.get<ExtendableSurvey[]>(
      `${environment.apiUrl}Survey/extendable?location=${office}&surveyType=${surveyType}`
    );
  }
  /**
   * @description GET list of AW Offices
   * @author &jo
   */
  getOfficeList(): Observable<string[]> {
    return this.http.get<string[]>(environment.apiUrl + "Survey/offices");
  }
  /**
   * @description Update due date with new value
   * @param data < DueDateExtensions[]>
   * @author &jo
   */
  updateExtendDate(data: DueDateExtensions[]): Observable<boolean> {
    return this.post(`${environment.apiUrl}Survey/extend`, data);
  }
  /**
   * @description Server call to fetch list of completed surveys which matches the search key from current quarter and previous quarter
   * @author &jo
   * @param search <string>
   */
  getCompletedSurveys(search: string): Observable<ExtendSurvey[]> {
    return this.http.get<ExtendSurvey[]>(
      `${
        environment.apiUrl
      }Survey/reopenable-surveys?nameMatch=${encodeURIComponent(search)}`
    );
  }
  /**
   * @description API to update survey with modified answers by HR admin
   * @param surveyId <String>ID of survey
   * @param data <SurveyEntry> Survey data with answers
   * @author &jo
   */
  updateSurvey(surveyId: string, data: SurveyEntry): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}Survey/update/${surveyId}`,
      data,
      { headers: this.headers }
    );
  }
  /**
   * @description API to fetch active delegation
   * @returns Observable<Delegation[]> Observable for API data
   * @author &jo
   */
  getExistingDelegation(): Observable<Delegation[]> {
    return this.http.get<Delegation[]>(
      `${environment.apiUrl}ManagerDelegations/current`
    );
  }
  /**
   * @description Add delegation
   * @param data <Delegation>
   */
  addDelegation(data: AddDelegation): Observable<boolean> {
    return this.post(`${environment.apiUrl}ManagerDelegations/add`, data);
  }
  /**
   * @description GET list of employees who are managers
   */
  getManagerList(): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      `${environment.apiUrl}ManagerDelegations/managers`
    );
  }
  getReporteesList(managerId: string): Observable<Reportee> {
    return this.http.get<Reportee>(
      `${environment.apiUrl}ManagerDelegations/direct-reports/${managerId}`
    );
  }
  stopDelegation(employeeList: string[]): Observable<boolean> {
    return this.post(
      `${environment.apiUrl}ManagerDelegations/stop`,
      employeeList
    );
  }

  private post = (url: string, body: any): Observable<boolean> => {
    return this.http.post<boolean>(url, body, { headers: this.headers });
  };

  private get = <T>(partialUrl: string): Observable<T> => {
    return this.http.get<T>(`${environment.apiUrl}Survey/${partialUrl}`);
  };
}
