<section class="metricsComponent">
  <div class="container">
    <div class="row">
      <div
        class="col-12 col-md form-inline justify-content-end align-items-start"
      >
        <button
          type="button"
          class="btn btn-primary btn-lg btnRed btnSearchSurveys"
          (click)="navigateToSearch()"
        >
          <span class="fa fa-search"></span> Search surveys
        </button>
      </div>
    </div>
    <div class="row">
      <!-- Form -->
      <div class="col-12 col-lg-6">
        <div class="greyBkg">
          <form id="metricsForm">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="metricsFormSurveyType">Survey type</label>
                  <select
                    class="form-control"
                    id="metricsFormSurveyType"
                    name="metricsFormSurveyType"
                    [(ngModel)]="selected.template"
                  >
                    <option
                      *ngFor="let temp of filterOptions.templates"
                      [ngValue]="temp"
                    >
                      {{ temp }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="metricsFormPeriod">Period</label>
                  <select
                    class="form-control"
                    id="metricsFormPeriod"
                    name="metricsFormPeriod"
                    [(ngModel)]="selected.period"
                  >
                    <option
                      *ngFor="let period of filterOptions.periods"
                      [ngValue]="period"
                    >
                      {{ period }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="metricsFormYear">Year</label>
                  <select
                    class="form-control"
                    id="metricsFormYear"
                    name="metricsFormYear"
                    [(ngModel)]="selected.year"
                  >
                    <option
                      *ngFor="let year of filterOptions.years"
                      [ngValue]="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                !!filterOptions.managers && filterOptions.managers.length > 1
              "
              class="row"
            >
              <div class="col-12">
                <div class="form-group">
                  <label for="metricsFormManager">Manager</label>
                  <select
                    class="form-control"
                    id="metricsFormManager"
                    name="metricsFormManager"
                    [(ngModel)]="selected.manager"
                  >
                    <option
                      *ngFor="let manager of filterOptions.managers"
                      [ngValue]="manager.networkId"
                    >
                      {{ manager.employeeName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="metricsFormOffice">Office</label>
                  <select
                    class="form-control"
                    id="metricsFormOffice"
                    name="metricsFormOffice"
                    [(ngModel)]="selected.office"
                  >
                    <option [ngValue]="undefined">Any Office</option>
                    <option
                      *ngFor="let office of filterOptions.offices"
                      [ngValue]="office"
                    >
                      {{ office }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="metricsFormLocation">Department</label>
                  <select
                    class="form-control"
                    id="metricsFormLocation"
                    name="metricsFormLocation"
                    [(ngModel)]="selected.department"
                  >
                    <option [ngValue]="undefined">Any Department</option>
                    <option
                      *ngFor="let department of filterOptions.departments"
                      [ngValue]="department"
                    >
                      {{ department }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg btnRed mt-2 mb-2"
                  (click)="viewMetrics()"
                >
                  <span class="fa fa-pie-chart"></span>
                  View Metrics
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Chart -->
      <div class="col-12 col-lg-6">
        <span class="chartNumbers"
          >{{ completedPercentage }} %<span>completed</span></span
        >
        <div class="chartMetrics">
          <canvas
            baseChart
            [data]="chartData"
            [labels]="chartLabels"
            [chartType]="'doughnut'"
            [legend]="false"
            [colors]="chartColors"
          ></canvas>
        </div>
        <span class="chartNumbers"
          >{{ pendingPercentage }} %<span>pending</span></span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dividerLine"></div>
      </div>
    </div>
    <div class="row">
      <!-- Pending list -->
      <div class="col-lg-6 surveys-column">
        <div class="dashboard-head">
          <div class="negativeColourBkg">
            <span>{{
              !!filteredPendingSurvey ? filteredPendingSurvey.length : 0
            }}</span>
          </div>
          <div>
            <h3><span class="fa fa-calendar"></span> Pending Surveys</h3>
          </div>
        </div>
        <div class="dashboard-list list-completed">
          <div
            class="tableCont tableScroll"
            *ngIf="!!filteredPendingSurvey && filteredPendingSurvey.length > 0"
          >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Office</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let metric of filteredPendingSurvey">
                  <td>{{ metric.name }}</td>
                  <td>{{ metric.office }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            class="dashboard-list-msg"
            *ngIf="filteredPendingSurvey.length == 0"
          >
            <span class="fa fa-info-circle"></span> No pending surveys.
          </p>
        </div>
      </div>
      <!-- Completed list -->
      <div class="col-lg-6 surveys-column">
        <div class="dashboard-head">
          <div class="positiveColourBkg">
            <span>{{
              !!filteredCompletedSurvey ? filteredCompletedSurvey.length : 0
            }}</span>
          </div>
          <div>
            <h3>
              <span class="fa fa-calendar-check-o"></span> Completed Surveys
            </h3>
          </div>
        </div>
        <div class="dashboard-list list-completed">
          <div
            class="tableCont tableScroll"
            *ngIf="
              !!filteredCompletedSurvey && filteredCompletedSurvey.length > 0
            "
          >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Office</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let metric of filteredCompletedSurvey">
                  <td>{{ metric.name }}</td>
                  <td>{{ metric.office }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p
            class="dashboard-list-msg"
            *ngIf="filteredCompletedSurvey.length == 0"
          >
            <span class="fa fa-info-circle"></span> No completed surveys for
            selected filters.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
