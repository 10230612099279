<section class="adminComponent">
  <div class="container">
    <div class="row">
      <div
        class="col-12 col-md form-inline justify-content-end align-items-start"
      >
        <button
          type="button"
          class="btn btn-primary btn-lg btnRed btnSearchSurveys"
          (click)="navigateToSearch()"
        >
          <span class="fa fa-search"></span> Search surveys
        </button>
      </div>
    </div>
    <div class="row separation">
      <div class="col-12 col-lg-12">
        <div class="greyBkg">
          <form id="metricsForm">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="metricsFormSurveyType">Operation type</label>
                  <select
                    [(ngModel)]="operation"
                    class="form-control"
                    name="operation"
                  >
                    <option value="">Select</option>
                    <option
                      title="Extend The Period of Not Completed Survey(s)"
                      value="Extend"
                    >
                      Extend the Period
                    </option>
                    <option title="Fix Incorrect Survey" value="Reopen">
                      Reopen and Fix the Completed Survey
                    </option>
                    <option value="Delegate">
                      Delegate Survey Responsibility To New Manager
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <srvy-reopen-and-fix *ngIf="operation === 'Reopen'"></srvy-reopen-and-fix>
    <srvy-extend *ngIf="operation === 'Extend'"></srvy-extend>
    <srvy-delegate *ngIf="operation === 'Delegate'"></srvy-delegate>
  </div>
</section>
