import { Component, OnInit } from "@angular/core";
import { DuedateModalComponent } from "../../modals/duedate-modal/duedate-modal.component";
import { DialogService } from "src/app/shared/modals/ng2-bootstrap-modal";
import { ToastrService } from "ngx-toastr";
import { GlobalConstants } from "src/app/shared/global-constants";
import { DashboardService } from "../../common/services/dashboard.service";
import {
  ExtendableSurvey,
  ExtendSurvey,
  DueDateExtensions,
} from "../../common/models";
@Component({
  selector: "srvy-extend",
  templateUrl: "./extend.component.html",
  styleUrls: ["./extend.component.css"],
})
export class ExtendComponent implements OnInit {
  public extendableSurveys: ExtendableSurvey[] = [];
  public serverError: boolean = false;
  public isLoading: boolean = false;
  public sortProp: string = "assignedToName";
  public maxItemsPerPage: number = 10;
  public pageNumber: number = 1;
  public searchText: string;
  public sortReverse: boolean;
  public surveyType: number = 2;
  public office: string = "";
  public enableExtendButton: boolean = false;
  public officeList: string[];
  constructor(
    private dialogService: DialogService,
    private dashboardService: DashboardService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getOfficeList();
    const adminDefaults = localStorage.getItem(
      GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY
    );
    localStorage.getItem(GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY);
    const localStorageData = JSON.parse(adminDefaults);
    if (localStorageData && localStorageData.maxItemsPerPage) {
      this.maxItemsPerPage = localStorageData.maxItemsPerPage;
    } else {
      this.maxItemsPerPage = 10;
    }
    if (localStorageData && localStorageData.office) {
      this.office = localStorageData.office;
      this.getExtendableSurveys();
    }
  }
  /**
   * @description Call location list api and call Extendable list api if it is not called already
   */
  getOfficeList() {
    this.isLoading = true;
    this.dashboardService.getOfficeList().subscribe((data: string[]) => {
      this.officeList = data;
      if (
        !this.office ||
        !this.officeList.find((item) => item === this.office)
      ) {
        this.office = data[0];
        this.getExtendableSurveys();
      }
    });
  }
  /**
   * @description Handle filter change
   */
  filterChanged() {
    this.getExtendableSurveys();
    if (this.office) {
      this.setDefault();
    }
  }
  /**
   * @description Expand/Collapse quarter section on expanding, close all other Quarters which all are kept expand
   * @param quarter <ExtendableSurvey>
   */
  expandQuarter(quarter: ExtendableSurvey) {
    if (!quarter.show) {
      this.extendableSurveys = this.extendableSurveys.map(
        (item: ExtendableSurvey) => {
          item.show = false;
          return item;
        }
      );
    }
    quarter.show = !quarter.show;
  }
  /**
   * Secondary sort on "targetName"
   */
  orderSurveys(data: ExtendableSurvey[]) {
    return data.map((quarter) => {
      if (!quarter.surveys) {
        return { ...quarter, surveys: [] };
      } else {
        return {
          ...quarter,
          surveys: quarter.surveys.sort((a, b) => {
            if (a.targetName === b.targetName) {
              return 0;
            }
            if (a.targetName > b.targetName) {
              return 1;
            }
            return -1;
          }),
        };
      }
    });
  }
  /**
   * @description Fetch list of due surveys
   */
  getExtendableSurveys() {
    this.isLoading = true;
    this.dashboardService
      .getExtendableSurveys(this.office, this.surveyType)
      .subscribe((data: any) => {
        this.isLoading = false;
        this.extendableSurveys = this.orderSurveys(data);
        if (this.extendableSurveys.length) {
          this.extendableSurveys[0].show = true;
        }
      });
    this.extendableSurveys = [];
    this.pageNumber = 1;
    this.enableExtendButton = false;
  }
  /**
   * @description Method is to set check box for select all option
   * @param Survey <ExtendableSurvey>
   */
  selectChange(survey: ExtendSurvey, quarter: ExtendableSurvey) {
    if (survey.check) {
      const unchecked = quarter.surveys.find((item) => {
        return !item.check;
      });
      quarter.check = !unchecked;
      this.enableExtendButton = true; // if at least one survey is checked, don't need to loop for enabling button
    } else {
      quarter.check = false;
      this.enableExtendButton = this.enableExtend();
    }
  }
  /**
   * @description Select All check box change event to update other row check box
   */
  checkAll(quarter: ExtendableSurvey) {
    quarter.surveys.forEach((survey) => {
      survey.check = quarter.check;
    });
    if (quarter.check) {
      if (!quarter.show) {
        this.expandQuarter(quarter);
      }
      this.enableExtendButton = true; // if at least one survey is checked, don't need to loop for enabling button
    } else {
      this.enableExtendButton = this.enableExtend();
    }
  }
  /**
   *@description Open DueDate Modal and receive data from due date model
   */
  openDue() {
    this.dialogService
      .addDialog(DuedateModalComponent, {
        surveyCount: this.getSelectedSurveyCount(),
      })
      .subscribe((result) => {
        if (result) {
          this.updateExtendDate(result);
        }
      });
  }
  /**
   * @description Call API for update and handle success and error
   * @param result < { date: Date, comment: string }>
   */
  updateExtendDate(result: { date: Date; comment: string }) {
    const data = this.getUpdateExtendDateData(result);
    this.isLoading = true;
    this.dashboardService.updateExtendDate(data).subscribe(
      (response) => {
        if (response) {
          this.getExtendableSurveys();
        } else {
          this.isLoading = false;
          this.toastr.error("Update failed. Try again.", "Error!!");
        }
      },
      (error) => {
        this.isLoading = false;
        this.toastr.error("Update failed. Try again.", "Error!!");
      }
    );
  }
  /**
   * @description Create data for update API from checked surveys from different quarter
   * @param result <Date and due date comment>
   */
  getUpdateExtendDateData(result: {
    date: Date;
    comment: string;
  }): DueDateExtensions[] {
    return this.extendableSurveys.reduce(
      (apiData: DueDateExtensions[], quarter: ExtendableSurvey) => {
        // destructure ExtendableSurvey[] to ExtendSurvey[]
        const quarterApiData = quarter.surveys
          .filter((item: ExtendSurvey) => {
            // Selected survey data from each quarter
            return item.check;
          })
          .reduce((filteredList: DueDateExtensions[], item: ExtendSurvey) => {
            // destructure ExtendSurvey[] to DueDateExtensions[]
            filteredList.push({
              surveyId: item.id,
              userNetworkId: item.assignedTo,
              dueDate: `${result.date.getFullYear()}-${(
                "0" +
                (result.date.getMonth() + 1)
              ).slice(-2)}-${("0" + result.date.getDate()).slice(-2)}T00:00:00`,
              comments: result.comment,
            });
            return filteredList;
          }, []);
        // concat ExtendSurvey[] of Quarter X with ExtendSurvey[] of Quarter Y
        apiData = apiData.concat(quarterApiData);
        return apiData;
      },
      []
    );
  }
  /**
   * @description set sort property to the given name  and set sortReverse
   * @param name <string>
   */
  sortType(name: string): void {
    if (this.sortProp === name) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortReverse = false;
    }
    this.sortProp = name;
  }
  /**
   * @description Set maxItemsPerPage property to the given value
   * @param itemsPerPage <Number>
   */
  setPaging(itemsPerPage: number): void {
    this.maxItemsPerPage = itemsPerPage;
    this.setDefault();
  }
  /**
   * @description This method id to set default filtering option in the page in localstorage
   */
  setDefault() {
    let localStorageData = JSON.parse(
      localStorage.getItem(GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY)
    );
    // take copy of local storage if it is already there else create one
    if (!localStorageData) {
      localStorageData = {};
    }
    localStorageData.maxItemsPerPage = this.maxItemsPerPage;
    localStorageData.office = this.office;
    localStorage.setItem(
      GlobalConstants.ADMINISTRATION_LOCALSTORAGE_KEY,
      JSON.stringify(localStorageData)
    );
  }
  /**
   * @description return false/true if no/any survey is selected
   */
  enableExtend(): boolean {
    return this.extendableSurveys.find((quarter: ExtendableSurvey) => {
      return quarter.surveys.find((item: ExtendSurvey) => {
        return item.check;
      })
        ? true
        : false;
    })
      ? true
      : false;
  }
  /**
   * @description get number of surveys selected for due date extension
   */
  getSelectedSurveyCount(): number {
    return this.extendableSurveys.reduce(
      (totalCount: number, quarter: ExtendableSurvey) => {
        return (
          totalCount +
          quarter.surveys.reduce((count: number, item: ExtendSurvey) => {
            return (count += item.check ? 1 : 0);
          }, 0)
        );
      },
      0
    );
  }
}
