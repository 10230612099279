import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutComponent } from "./shared";
import { NotFoundErrorPageComponent, ApiErrorPageComponent } from "./shared";

import { LAYOUT_CHILD_ROUTES } from "./shared/routes";
import { UnsupportedBrowserErrorPageComponent } from "./shared/unsupported-browser-error-page/unsupported-browser-error-page.component";
import { AuthenticationGuardService } from "./auth";

export const appRoutes: Routes = [
  { path: "", component: LayoutComponent, children: LAYOUT_CHILD_ROUTES },
  {
    path: "unsupported_browser",
    component: UnsupportedBrowserErrorPageComponent,
    canActivate: [AuthenticationGuardService],
  },
  {
    path: "serverError",
    component: ApiErrorPageComponent,
    data: { errorData: null },
  },
  { path: "**", component: NotFoundErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
